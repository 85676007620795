define("ember-dayjs/dist/_app_/helpers/dayjs-duration-humanize", ["exports", "ember-dayjs/helpers/dayjs-duration-humanize.js"], function (_exports, _dayjsDurationHumanize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dayjsDurationHumanize.default;
    }
  });
});