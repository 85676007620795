define("ember-dayjs/dist/helpers/dayjs-weekdays-short", ["exports", "ember-dayjs/dist/helpers/base-helper.js", "ember-dayjs/dist/_rollupPluginBabelHelpers-7db585ca.js", "@ember/component/helper", "@ember/runloop", "@ember/service"], function (_exports, _baseHelper, _rollupPluginBabelHelpers7db585ca, _helper, _runloop, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DayjsWeekdaysShort extends _baseHelper.default {
    async compute(params, hash) {
      super.compute(params, hash);
      await this.dayjs.extend('localeData');
      return this.dayjs.self.weekdaysShort();
    }
  }
  _exports.default = DayjsWeekdaysShort;
});