define("ember-dayjs/dist/_app_/helpers/dayjs-is-same-or-after", ["exports", "ember-dayjs/helpers/dayjs-is-same-or-after.js"], function (_exports, _dayjsIsSameOrAfter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dayjsIsSameOrAfter.default;
    }
  });
});