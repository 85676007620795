define("ember-dayjs/dist/helpers/dayjs", ["exports", "ember-dayjs/dist/helpers/base-helper.js", "@ember/utils", "ember-dayjs/dist/_rollupPluginBabelHelpers-7db585ca.js", "@ember/component/helper", "@ember/runloop", "@ember/service"], function (_exports, _baseHelper, _utils, _rollupPluginBabelHelpers7db585ca, _helper, _runloop, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Dayjs extends _baseHelper.default {
    async compute(params, hash) {
      var _hash$locale, _hash$locale2;
      super.compute(params, hash);
      await this.dayjs.useLocale((_hash$locale = hash.locale) !== null && _hash$locale !== void 0 ? _hash$locale : this.dayjs.locale);
      if ((0, _utils.typeOf)(params) === 'object') {
        await this.dayjs.extend('objectSupport');
      }
      return this.dayjs.self(...params).locale((_hash$locale2 = hash.locale) !== null && _hash$locale2 !== void 0 ? _hash$locale2 : this.dayjs.locale);
    }
  }
  _exports.default = Dayjs;
});