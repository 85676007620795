define("ember-dayjs/dist/helpers/base-helper", ["exports", "ember-dayjs/dist/_rollupPluginBabelHelpers-7db585ca.js", "@ember/component/helper", "@ember/runloop", "@ember/service"], function (_exports, _rollupPluginBabelHelpers7db585ca, _helper, _runloop, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  let BaseHelper = (_class = class BaseHelper extends _helper.default {
    constructor() {
      super(...arguments);
      (0, _rollupPluginBabelHelpers7db585ca.a)(this, "dayjs", _descriptor, this);
    }
    compute(_params) {
      let hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.clearTimer();
      if (hash.interval) {
        this.intervalTimer = setTimeout(() => {
          (0, _runloop.run)(() => this.recompute());
        }, parseInt(hash.interval, 10));
      }
    }
    clearTimer() {
      clearTimeout(this.intervalTimer);
    }
  }, _descriptor = (0, _rollupPluginBabelHelpers7db585ca._)(_class.prototype, "dayjs", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  _exports.default = BaseHelper;
});