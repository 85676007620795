define("@1024pix/pix-ui/components/pix-filter-banner", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="pix-filter-banner" ...attributes>
    {{#if this.displayTitle}}
      <div class="pix-filter-banner__container-title">
        <p class="pix-filter-banner__title">{{@title}}</p>
      </div>
    {{/if}}
  
    <div class="pix-filter-banner__container-filter">
      {{yield}}
    </div>
  
    {{#if this.displayActionMenu}}
      <div class="pix-filter-banner__container-action">
        {{#if this.displayDetails}}
          <p class="pix-filter-banner__details">{{@details}}</p>
        {{/if}}
  
        {{#if this.displayClearFilters}}
          <div class="pix-filter-banner__button-container">
            <PixButton
              class="pix-filter-banner__button"
              @shape="squircle"
              @size="small"
              @triggerAction={{@onClearFilters}}
            >
              <FaIcon class="pix-filter-banner-button__icon" @icon="trash-can" @prefix="far" />
              {{@clearFiltersLabel}}
            </PixButton>
          </div>
        {{/if}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "P81ZA3bj",
    "block": "[[[11,0],[24,0,\"pix-filter-banner\"],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"displayTitle\"]],[[[1,\"    \"],[10,0],[14,0,\"pix-filter-banner__container-title\"],[12],[1,\"\\n      \"],[10,2],[14,0,\"pix-filter-banner__title\"],[12],[1,[30,2]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,0,\"pix-filter-banner__container-filter\"],[12],[1,\"\\n    \"],[18,6,null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"displayActionMenu\"]],[[[1,\"    \"],[10,0],[14,0,\"pix-filter-banner__container-action\"],[12],[1,\"\\n\"],[41,[30,0,[\"displayDetails\"]],[[[1,\"        \"],[10,2],[14,0,\"pix-filter-banner__details\"],[12],[1,[30,3]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"displayClearFilters\"]],[[[1,\"        \"],[10,0],[14,0,\"pix-filter-banner__button-container\"],[12],[1,\"\\n          \"],[8,[39,2],[[24,0,\"pix-filter-banner__button\"]],[[\"@shape\",\"@size\",\"@triggerAction\"],[\"squircle\",\"small\",[30,4]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,3],[[24,0,\"pix-filter-banner-button__icon\"]],[[\"@icon\",\"@prefix\"],[\"trash-can\",\"far\"]],null],[1,\"\\n            \"],[1,[30,5]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\",\"@title\",\"@details\",\"@onClearFilters\",\"@clearFiltersLabel\",\"&default\"],false,[\"if\",\"yield\",\"pix-button\",\"fa-icon\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-filter-banner.hbs",
    "isStrictMode": false
  });
  class PixFilterBanner extends _component2.default {
    get displayTitle() {
      return Boolean(this.args.title);
    }
    get displayDetails() {
      return Boolean(this.args.details);
    }
    get displayClearFilters() {
      return Boolean(this.args.clearFiltersLabel);
    }
    get displayActionMenu() {
      return this.displayClearFilters || this.displayDetails;
    }
  }
  _exports.default = PixFilterBanner;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixFilterBanner);
});