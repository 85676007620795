define("@1024pix/pix-ui/components/pix-message", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <p class="pix-message {{concat 'pix-message--' this.type}}" ...attributes>
    {{#if @withIcon}}
      <FaIcon @icon={{this.iconClass}} />
    {{/if}}
    <span class="pix-message__content">
      {{yield}}
    </span>
  </p>
  */
  {
    "id": "LFbOsfDo",
    "block": "[[[11,2],[16,0,[29,[\"pix-message \",[28,[37,0],[\"pix-message--\",[30,0,[\"type\"]]],null]]]],[17,1],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[8,[39,2],null,[[\"@icon\"],[[30,0,[\"iconClass\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,1],[14,0,\"pix-message__content\"],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@withIcon\",\"&default\"],false,[\"concat\",\"if\",\"fa-icon\",\"yield\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-message.hbs",
    "isStrictMode": false
  });
  const TYPE_INFO = 'info';
  const TYPE_SUCCESS = 'success';
  const TYPE_WARNING = 'warning';
  const TYPE_ALERT = 'alert';
  const TYPE_ERROR = 'error';
  class PixMessage extends _component2.default {
    get type() {
      const correctTypes = [TYPE_INFO, TYPE_SUCCESS, TYPE_WARNING, TYPE_ALERT, TYPE_ERROR];
      if (this.args.type === 'alert') {
        console.warn('ERROR in PixMessage component, "alert" type is deprecated. Use "error" type instead.');
      }
      return correctTypes.includes(this.args.type) ? this.args.type : 'info';
    }
    get iconClass() {
      const classes = {
        [TYPE_INFO]: 'circle-info',
        [TYPE_SUCCESS]: 'circle-check',
        [TYPE_WARNING]: 'circle-exclamation',
        [TYPE_ALERT]: 'triangle-exclamation',
        [TYPE_ERROR]: 'triangle-exclamation'
      };
      return classes[this.type];
    }
  }
  _exports.default = PixMessage;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixMessage);
});