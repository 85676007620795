define("@1024pix/pix-ui/components/pix-progress-gauge", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/template"], function (_exports, _component, _templateFactory, _component2, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="progress-gauge
      {{this.progressGaugeClass}}
      {{if @isArrowLeft 'progress-gauge--tooltip-left'}}"
    ...attributes
  >
    <div class="progress-gauge__referrer"></div>
    <div class="progress-gauge__marker" aria-hidden="true" style={{this.valueGaugeStyle}}></div>
  
    {{#if @tooltipText}}
      <div class="progress-gauge__tooltip-wrapper" style={{this.valueGaugeStyle}}>
        <span class="progress-gauge__tooltip">{{@tooltipText}}</span>
      </div>
    {{/if}}
  
    {{#if this.hasSubtitle}}
      <p class="progress-gauge__sub-title">{{@subtitle}}</p>
    {{/if}}
  </div>
  */
  {
    "id": "ceq0g3/n",
    "block": "[[[11,0],[16,0,[29,[\"progress-gauge\\n    \",[30,0,[\"progressGaugeClass\"]],\"\\n    \",[52,[30,1],\"progress-gauge--tooltip-left\"]]]],[17,2],[12],[1,\"\\n  \"],[10,0],[14,0,\"progress-gauge__referrer\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"progress-gauge__marker\"],[14,\"aria-hidden\",\"true\"],[15,5,[30,0,[\"valueGaugeStyle\"]]],[12],[13],[1,\"\\n\\n\"],[41,[30,3],[[[1,\"    \"],[10,0],[14,0,\"progress-gauge__tooltip-wrapper\"],[15,5,[30,0,[\"valueGaugeStyle\"]]],[12],[1,\"\\n      \"],[10,1],[14,0,\"progress-gauge__tooltip\"],[12],[1,[30,3]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"hasSubtitle\"]],[[[1,\"    \"],[10,2],[14,0,\"progress-gauge__sub-title\"],[12],[1,[30,4]],[13],[1,\"\\n\"]],[]],null],[13]],[\"@isArrowLeft\",\"&attrs\",\"@tooltipText\",\"@subtitle\"],false,[\"if\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-progress-gauge.hbs",
    "isStrictMode": false
  });
  class PixProgressGauge extends _component2.default {
    get progressValue() {
      if (!this.args.value || this.args.value < 0) {
        return 0;
      }
      return this.args.value > 100 ? 100 : this.args.value;
    }
    get valueGaugeStyle() {
      return (0, _template.htmlSafe)(`width: ${this.progressValue}%`);
    }
    get hasSubtitle() {
      return !!this.args.subtitle;
    }
    get progressGaugeClass() {
      const availableColor = ['yellow', 'white'];
      const color = this.args.color && availableColor.includes(this.args.color) ? this.args.color : `yellow`;
      return `progress-gauge--${color}`;
    }
  }
  _exports.default = PixProgressGauge;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixProgressGauge);
});