define("@1024pix/pix-ui/components/pix-toggle", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label class={{this.className}}>
    <span class="pix-toggle__label{{if @screenReaderOnly ' screen-reader-only'}}">{{@label}}</span>
    <button
      class="pix-toggle__button"
      aria-pressed={{if @toggled "true" "false"}}
      type="button"
      {{on "click" this.onToggle}}
    >
      <span class="pix-toggle__on">
        {{#if @onLabel}}
          {{@onLabel}}
        {{else}}
          {{yield to="on"}}
        {{/if}}
      </span>
      <span class="pix-toggle__off">
        {{#if @offLabel}}
          {{@offLabel}}
        {{else}}
          {{yield to="off"}}
        {{/if}}
      </span>
    </button>
  </label>
  */
  {
    "id": "laf8NAlj",
    "block": "[[[10,\"label\"],[15,0,[30,0,[\"className\"]]],[12],[1,\"\\n  \"],[10,1],[15,0,[29,[\"pix-toggle__label\",[52,[30,1],\" screen-reader-only\"]]]],[12],[1,[30,2]],[13],[1,\"\\n  \"],[11,\"button\"],[24,0,\"pix-toggle__button\"],[16,\"aria-pressed\",[52,[30,3],\"true\",\"false\"]],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"onToggle\"]]],null],[12],[1,\"\\n    \"],[10,1],[14,0,\"pix-toggle__on\"],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"        \"],[1,[30,4]],[1,\"\\n\"]],[]],[[[1,\"        \"],[18,6,null],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"pix-toggle__off\"],[12],[1,\"\\n\"],[41,[30,5],[[[1,\"        \"],[1,[30,5]],[1,\"\\n\"]],[]],[[[1,\"        \"],[18,7,null],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@screenReaderOnly\",\"@label\",\"@toggled\",\"@onLabel\",\"@offLabel\",\"&on\",\"&off\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-toggle.hbs",
    "isStrictMode": false
  });
  let PixToggle = (_class = class PixToggle extends _component2.default {
    get className() {
      const classes = ['pix-toggle'];
      if (this.args.inline) {
        classes.push('pix-toggle--inline');
      }
      if (this.args.toggled) {
        classes.push('pix-toggle--pressed');
      }
      if (this.args.screenReaderOnly) {
        classes.push('pix-toggle--screen-reader-only');
      }
      return classes.join(' ');
    }
    onToggle() {
      this.args.onChange(!this.args.toggled);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onToggle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onToggle"), _class.prototype)), _class);
  _exports.default = PixToggle;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixToggle);
});