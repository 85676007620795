define("@1024pix/pix-ui/components/pix-input-code", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-down-event-binding }}
  <div class="pix-input-code">
    <fieldset aria-describedby="pix-input-code__details-of-use">
      <p id="pix-input-code__details-of-use">{{this.explanationOfUse}}</p>
      <legend>{{this.legend}}</legend>
      {{#each this.numberOfIterations as |i|}}
        <input
          ...attributes
          id="code-input-{{i}}"
          type="{{this.inputType}}"
          aria-label="{{this.ariaLabel}} {{i}}"
          class="pix-input-code__input"
          min="1"
          max="9"
          autocomplete="off"
          placeholder="{{this.placeholder}}"
          {{on "keydown" this.handleArrowUpOrDown}}
          {{on "keyup" (fn this.handleKeyUp i)}}
          {{on "input" (fn this.handleCodeInput i)}}
          {{on "paste" (fn this.handlePaste i)}}
        />
      {{/each}}
    </fieldset>
  </div>
  */
  {
    "id": "X5bU7tUL",
    "block": "[[[10,0],[14,0,\"pix-input-code\"],[12],[1,\"\\n  \"],[10,\"fieldset\"],[14,\"aria-describedby\",\"pix-input-code__details-of-use\"],[12],[1,\"\\n    \"],[10,2],[14,1,\"pix-input-code__details-of-use\"],[12],[1,[30,0,[\"explanationOfUse\"]]],[13],[1,\"\\n    \"],[10,\"legend\"],[12],[1,[30,0,[\"legend\"]]],[13],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"numberOfIterations\"]]],null]],null],null,[[[1,\"      \"],[11,\"input\"],[17,2],[16,1,[29,[\"code-input-\",[30,1]]]],[16,4,[29,[[30,0,[\"inputType\"]]]]],[16,\"aria-label\",[29,[[30,0,[\"ariaLabel\"]],\" \",[30,1]]]],[24,0,\"pix-input-code__input\"],[24,\"min\",\"1\"],[24,\"max\",\"9\"],[24,\"autocomplete\",\"off\"],[16,\"placeholder\",[29,[[30,0,[\"placeholder\"]]]]],[4,[38,2],[\"keydown\",[30,0,[\"handleArrowUpOrDown\"]]],null],[4,[38,2],[\"keyup\",[28,[37,3],[[30,0,[\"handleKeyUp\"]],[30,1]],null]],null],[4,[38,2],[\"input\",[28,[37,3],[[30,0,[\"handleCodeInput\"]],[30,1]],null]],null],[4,[38,2],[\"paste\",[28,[37,3],[[30,0,[\"handlePaste\"]],[30,1]],null]],null],[12],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"i\",\"&attrs\"],false,[\"each\",\"-track-array\",\"on\",\"fn\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-input-code.hbs",
    "isStrictMode": false
  });
  const ERROR_MESSAGE = 'ERROR in PixInputCode component, you must provide an @ariaLabel and a @legend';
  const NAVIGATION_EXPLANATION = 'Pour se déplacer de champ en champ vous pouvez utiliser la tabulation ou bien les flèches gauche et droite de votre clavier.';
  const NUMBER_INPUT_EXPLANATION = 'Pour remplir un champ vous pouvez utiliser des chiffres de 1 à 9 ou bien les flèches haut et bas de votre clavier pour incrémenter de 1 la valeur du champ.';
  const TEXT_INPUT_EXPLANATION = 'Pour remplir un champ vous pouvez utiliser les caractères alphanumériques de votre clavier.';
  let PixInputCode = (_class = class PixInputCode extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "moveFocus", true);
      _defineProperty(this, "numInputs", this.args.numInputs || 6);
    }
    get numberOfIterations() {
      return Array.from({
        length: this.numInputs
      }, (_, i) => i + 1);
    }
    get ariaLabel() {
      if (!this.args.ariaLabel) {
        throw new Error(ERROR_MESSAGE);
      }
      return this.args.ariaLabel;
    }
    get explanationOfUse() {
      const defaultFillingExplanation = this.inputType === 'number' ? NUMBER_INPUT_EXPLANATION : TEXT_INPUT_EXPLANATION;
      const defaultExplanationOfUseMessage = NAVIGATION_EXPLANATION + ' ' + defaultFillingExplanation;
      return this.args.explanationOfUse ? this.args.explanationOfUse : defaultExplanationOfUseMessage;
    }
    get legend() {
      if (!this.args.legend) {
        throw new Error(ERROR_MESSAGE);
      }
      return this.args.legend;
    }
    get inputType() {
      return this.args.inputType || 'number';
    }
    get placeholder() {
      return this.inputType === 'number' ? '0' : null;
    }
    focusElement(index) {
      const nextElem = document.getElementById(`code-input-${index}`);
      nextElem && nextElem.focus();
    }
    validateInput(elem) {
      if (this.inputType === 'number') {
        const startsWithDigit = elem.value.match(/^[1-9]/);
        if (!startsWithDigit) elem.value = '';
      }
      if (elem.value.length > 1) elem.value = [...elem.value][0];
    }
    triggerAction() {
      if (!this.args.onAllInputsFilled) return;
      const code = [];
      for (let i = 1; i <= this.numInputs; i++) {
        const elem = document.getElementById(`code-input-${i}`);
        elem.value.length > 0 && code.push(elem.value);
      }
      if (code.length === this.numInputs) {
        this.args.onAllInputsFilled(code.join(''));
      }
    }
    handleCodeInput(index) {
      const elem = document.getElementById(`code-input-${index}`);
      this.validateInput(elem);
      if (elem.value.length > 0) {
        elem.classList.add('filled');
        this.moveFocus && this.focusElement(index + 1);
        this.triggerAction();
      } else {
        elem.classList.remove('filled');
      }
    }
    handleKeyUp(index, event) {
      const eventMap = {
        Backspace: index - 1,
        ArrowLeft: index - 1,
        ArrowRight: index + 1
      };
      const newIndex = eventMap[event.code || event.key];
      this.focusElement(newIndex);
    }
    handleArrowUpOrDown(event) {
      this.moveFocus = true;
      const eventCode = event.code || event.key;
      if (['ArrowUp', 'ArrowDown'].includes(eventCode)) this.moveFocus = false;
    }
    handlePaste(index, event) {
      event.preventDefault();
      event.stopPropagation();
      const pastedText = (event.clipboardData || window.clipboardData).getData('text');
      const pastedTextWithOnlyValidCharacters = _extractValidCharacters(pastedText);
      pastedTextWithOnlyValidCharacters.forEach(char => {
        const input = document.getElementById(`code-input-${index}`);
        if (input) {
          this.focusElement(index);
          input.value = char;
          input.classList.add('filled');
          index++;
        }
      });
      this.focusElement(index);
      this.triggerAction();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "handleCodeInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleCodeInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleKeyUp", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeyUp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleArrowUpOrDown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleArrowUpOrDown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handlePaste", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handlePaste"), _class.prototype)), _class);
  _exports.default = PixInputCode;
  function _extractValidCharacters(pastedText) {
    const alphanumericCharacters = /^[a-zA-Z0-9_]*$/;
    return [...pastedText].filter(char => alphanumericCharacters.test(char));
  }
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixInputCode);
});