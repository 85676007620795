define("ember-dayjs/dist/helpers/dayjs-date-of-month", ["exports", "ember-dayjs/dist/helpers/base-helper.js", "ember-dayjs/dist/_rollupPluginBabelHelpers-7db585ca.js", "@ember/component/helper", "@ember/runloop", "@ember/service"], function (_exports, _baseHelper, _rollupPluginBabelHelpers7db585ca, _helper, _runloop, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DayjsDateOfMonth extends _baseHelper.default {
    compute(params, hash) {
      super.compute(params, hash);
      return this.dayjs.self(params[0]).date(params[1]);
    }
  }
  _exports.default = DayjsDateOfMonth;
});