define("@1024pix/pix-ui/components/pix-background-header", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="pix-background-header" ...attributes>
    <div class="pix-background-header__background"></div>
  
    <div class="pix-background-header__content">
      {{yield}}
    </div>
  </div>
  */
  {
    "id": "qt61q85R",
    "block": "[[[11,0],[24,0,\"pix-background-header\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"pix-background-header__background\"],[12],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"pix-background-header__content\"],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-background-header.hbs",
    "isStrictMode": false
  });
  class PixBackgroundHeaderComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "text", 'pix-background-header');
    }
  }
  _exports.default = PixBackgroundHeaderComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixBackgroundHeaderComponent);
});