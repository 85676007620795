define("ember-dayjs/dist/_app_/helpers/dayjs-subtract", ["exports", "ember-dayjs/helpers/dayjs-subtract.js"], function (_exports, _dayjsSubtract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dayjsSubtract.default;
    }
  });
});