define("ember-dayjs/dist/services/dayjs", ["exports", "ember-dayjs/dist/_rollupPluginBabelHelpers-7db585ca.js", "dayjs", "@ember/utils", "@ember/service", "@ember/debug", "@glimmer/tracking"], function (_exports, _rollupPluginBabelHelpers7db585ca, _dayjs, _utils, _service, _debug, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  let DayjsService = (_class = class DayjsService extends _service.default {
    constructor() {
      super(...arguments);
      (0, _rollupPluginBabelHelpers7db585ca.a)(this, "locale", _descriptor, this);
    }
    get self() {
      return _dayjs.default;
    }
    async setLocale(locale) {
      await this.useLocale(locale);
      this.locale = locale;
    }
    async useLocale(locale) {
      (false && !((0, _utils.isPresent)(locale)) && (0, _debug.assert)('Locale cannot be null.', (0, _utils.isPresent)(locale)));
      const localeName = locale.toLowerCase();
      if ((0, _utils.isEqual)(localeName, 'en')) {
        return;
      }
      try {
        const importedLocale = await import(`dayjs/locale/${localeName}.js`);
        (false && !((0, _utils.isPresent)(importedLocale)) && (0, _debug.assert)(`${localeName} locale not found. Please make sure you typed the locale name correctly.`, (0, _utils.isPresent)(importedLocale)));
      } catch {
        throw new Error(`${localeName} locale not found. Please make sure you typed the locale name correctly.`);
      }
    }
    async setTimeZone(timeZone) {
      await this.extend('timezone');
      this.self.tz.setDefault(timeZone);
    }
    async resetTimezone() {
      await this.setTimeZone();
    }
    async extend(plugin) {
      (false && !((0, _utils.isPresent)(plugin)) && (0, _debug.assert)('Plugin name cannot be null.', (0, _utils.isPresent)(plugin)));
      try {
        const importedPlugin = await import(`dayjs/plugin/${plugin}`);
        (false && !((0, _utils.isPresent)(importedPlugin)) && (0, _debug.assert)(`${plugin} plugin not found. Please make sure you typed the plugin name correctly.`, (0, _utils.isPresent)(importedPlugin)));
        this.self.extend(importedPlugin);
      } catch {
        throw new Error(`${plugin} plugin not found. Please make sure you typed the plugin name correctly.`);
      }
    }
  }, _descriptor = (0, _rollupPluginBabelHelpers7db585ca._)(_class.prototype, "locale", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'en';
    }
  }), _class);
  _exports.default = DayjsService;
});