define("@1024pix/pix-ui/components/pix-checkbox", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="pix-checkbox">
    <input
      id={{@id}}
      type="checkbox"
      class="pix-checkbox__input {{if @isIndeterminate ' pix-checkbox__input--indeterminate'}}"
      checked={{@checked}}
      ...attributes
    />
    <label
      class="pix-checkbox__label {{this.labelSizeClass}} {{if @screenReaderOnly 'sr-only'}}"
      for={{@id}}
    >
      {{#if (has-block)}}
        {{yield}}
      {{else}}
        yield required to give a label for PixCheckBox
        {{@id}}.
      {{/if}}
    </label>
  </div>
  */
  {
    "id": "CoFoexO2",
    "block": "[[[10,0],[14,0,\"pix-checkbox\"],[12],[1,\"\\n  \"],[11,\"input\"],[16,1,[30,1]],[24,4,\"checkbox\"],[16,0,[29,[\"pix-checkbox__input \",[52,[30,2],\" pix-checkbox__input--indeterminate\"]]]],[16,\"checked\",[30,3]],[17,4],[12],[13],[1,\"\\n  \"],[10,\"label\"],[15,0,[29,[\"pix-checkbox__label \",[30,0,[\"labelSizeClass\"]],\" \",[52,[30,5],\"sr-only\"]]]],[15,\"for\",[30,1]],[12],[1,\"\\n\"],[41,[48,[30,6]],[[[1,\"      \"],[18,6,null],[1,\"\\n\"]],[]],[[[1,\"      yield required to give a label for PixCheckBox\\n      \"],[1,[30,1]],[1,\".\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@id\",\"@isIndeterminate\",\"@checked\",\"&attrs\",\"@screenReaderOnly\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-checkbox.hbs",
    "isStrictMode": false
  });
  const labelSizeToClass = new Map([['small', 'pix-checkbox__label--small'], ['default', 'pix-checkbox__label--default'], ['large', 'pix-checkbox__label--large']]);
  class PixCheckbox extends _component2.default {
    constructor() {
      super(...arguments);
      if (!this.args.id || !this.args.id.toString().trim()) {
        throw new Error('ERROR in PixCheckbox component, @id param is not provided');
      }
    }
    get labelSizeClass() {
      return labelSizeToClass.get(this.args.labelSize);
    }
  }
  _exports.default = PixCheckbox;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixCheckbox);
});