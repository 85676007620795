define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en", {
    "api-error-messages": {
      "campaign-creation": {
        "custom-landing-page_too_long": "Please choose a shorter text to display on the starting page.",
        "external-user-id-required": "Please specify the type of external user ID which the participants will be required to fill when starting the customised test.",
        "name-required": "Please name your campaign.",
        "owner_not_in_organization": "Please select a member of your organization.",
        "purpose-required": "Please select the purpose of your campaign: assess participants or collect their profiles.",
        "target-profile-required": "Please select a customised test for your campaign.",
        "title_too_long": "Please choose a shorter title."
      },
      "default": "The service is temporarily unavailable. Please try again later.",
      "edit-student-number": {
        "student-number-exists": "The student number entered is already used by {firstName} {lastName}"
      },
      "global": "An error occurred. Please try again later.",
      "or-separator": " or ",
      "student-csv-import": {
        "bad-csv-format": "The file must be in csv format with comma or semicolon separator.",
        "encoding-not-supported": "File encoding not supported.",
        "field-bad-values": "Row {line} : The field “{field}” must be “{valids}”.",
        "field-date-format": "Row {line} : The field “{field}” must be in the format dd/mm/yyyy.",
        "field-email-format": "Row {line} : The field “{field}” must be a valid email address.",
        "field-length": "Row {line} : The field “{field}” must contain {limit} characters.",
        "field-max-length": "Row {line} : The field “{field}” must contain less than {limit} characters.",
        "field-min-length": "Row {line} : The field “{field}” must contain at least {limit} characters.",
        "field-required": "Row {line} : The field “{field}” is mandatory.",
        "header-required": "The “{field}” is mandatory.",
        "header-unknown": "Column headers must be identical to the ones on the template.",
        "identifier-unique": "Row {line} : The field “{field}” of this row is repeated several times within the file.",
        "insee-code-invalid": "Row {line} : The field “{field}” is not in the INSEE format.",
        "payload-too-large": "The file must be less than {maxSize}MB.",
        "student-number-format": "Row {line} : The field “{field}” must not contain any special characters.",
        "student-number-unique": "Row {line} : The field “{field}” must be unique within the file."
      },
      "student-xml-import": {
        "birth-city-code-required-for-french-student": "The field birth city code is mandatory for each student born in France.",
        "empty": "Each student must have an INE and a class.",
        "encoding-not-supported": "File encoding not supported.",
        "ine-required": "INE is mandatory for each student.",
        "ine-unique": "The INE {nationalStudentId} is repeated several times within the file for several students.",
        "invalid-file": "File does not match the requirements.",
        "invalid-file-extension": "File with extension {fileExtension} not supported.",
        "payload-too-large": "The file must be less than {maxSize}MB.",
        "sex-code-required": "The field sex is mandatory for each student.",
        "uai-mismatched": "The UAI of your SIECLE file does not match your school."
      }
    },
    "banners": {
      "campaigns": {
        "message": "Don't forget to create your back-to-school campaigns and share the codes with your students before November. '<'a href={documentationLink} class=\"{linkClasses}\" target=\"_blank\" rel=\"noopener noreferrer\"'>'More info'</a>'"
      },
      "import": {
        "message": "'<strong>'Start of school year:'</strong>' the admin must '<'a href=\"/eleves\" class=\"{linkClasses}\"'>'import the students database'</a>' to start using Pix Orga. '<'a href={documentationLink} class=\"{linkClasses}\" target=\"_blank\" rel=\"noopener noreferrer\"'>'Learn more'</a>'"
      }
    },
    "cards": {
      "participants-average-results": {
        "information": "Find here the average results of your campaign. This includes all the participants who have completed their customised test and sent their results.",
        "title": "Average result"
      },
      "participants-average-stages": {
        "information": "Find here the average rating of your campaign. This includes all the participants who have completed their customised test and sent their results.",
        "title": "Average rating"
      },
      "participants-count": {
        "information": "Find here the total number of participations in your campaign. This includes all the participants who have entered the code and started their customised test or submitted their profile.",
        "loader": "Loading total participants",
        "title": "Total participants"
      },
      "submitted-count": {
        "information": "Find here the results submitted by your participants. This includes all the participants who have finished and clicked on the button \"Submit my results\" or \"Submit my profile\".",
        "loader": "Loading submitted results or profiles",
        "title": "Submitted results",
        "title-profiles": "Submitted profiles"
      }
    },
    "charts": {
      "participants-by-day": {
        "labels-a11y": {
          "date": "Date",
          "shared": "Submitted results",
          "shared-profile": "Submitted profiles",
          "started": "Total participants"
        },
        "labels-legend": {
          "shared": "Submitted results",
          "shared-profile": "Submitted profiles",
          "started": "Total participants"
        },
        "loader": "Loading participant's activity",
        "title": "Participant's activity"
      },
      "participants-by-mastery-percentage": {
        "label-a11y": "From {from, number, ::percent} to {to, number, ::percent}: {count, plural, =0 {0 participant} =1 {1 participant} other {{count, number} participants}}",
        "loader": "Loading participants grouped by success rates",
        "title": "Distribution of participants grouped by success rate",
        "tooltip": {
          "label": "Number of participants : {count}",
          "legend": "{from, number, ::percent} - {to, number, ::percent}",
          "title": "Results {legend}"
        }
      },
      "participants-by-stage": {
        "label-a11y": "{stage} out of {totalStage} stars",
        "loader": "Loading and sorting the participants grouped by success rates",
        "participants": "{count, plural, =0 {0 participants} =1 {1 participant} other {{count, number} participants}}",
        "percentage": "{percentage} %",
        "title": "Distribution of participants grouped by success rate"
      },
      "participants-by-status": {
        "labels-a11y": {
          "completed": "{count, plural, =0 {0 participants} =1 {1 participant} other {{count, number} participants}} with pending results",
          "shared": "{count, plural, =0 {0 participants} =1 {1 participant} other {{count, number} participants}} with submitted results",
          "shared-profile": "{count, plural, =0 {0 participants} =1 {1 participant} other {{count, number} participants}} with submitted profiles",
          "started": "{count, plural, =0 {0 participants} =1 {1 participant} other {{count, number} participants}} in progress"
        },
        "labels-legend": {
          "completed-assessment": "Pending results ({count})",
          "completed-assessment-tooltip": "Pending results: These participants have finished their customised test but haven’t submitted their results yet.",
          "completed-profile": "Pending profiles ({count})",
          "completed-profile-tooltip": "Pending profiles: These participants haven’t submitted their profiles yet.",
          "shared": "Submitted results ({count})",
          "shared-profile": "Submitted profiles ({count})",
          "shared-profile-tooltip": "Submitted profiles: These participants have submitted their profiles.",
          "shared-tooltip": "Submitted results: These participants have finished their customised test and submitted their results.",
          "started": "In progress ({count})",
          "started-tooltip": "In progress: These participants haven’t finished their customised test yet."
        },
        "labels-tooltip": {
          "completed-assessment": "Pending results: {percentage, number, ::percent .0}",
          "completed-profile": "Pending profiles: {percentage, number, ::percent .0}",
          "shared": "Submitted results: {percentage, number, ::percent .0}",
          "shared-profile": "Submitted profiles: {percentage, number, ::percent .0}",
          "started": "In progress: {percentage, number, ::percent .0}"
        },
        "loader": "Loading statuses distribution",
        "title": "Statuses"
      }
    },
    "common": {
      "actions": {
        "back": "Back",
        "cancel": "Cancel",
        "close": "Close",
        "global": "Actions"
      },
      "api-error-messages": {
        "bad-request-error": "The data entered was not in the correct format.",
        "gateway-timeout-error": "The service is being slow. Please try again later.",
        "internal-server-error": "An error occurred, our teams are working on finding a solution. Please try again later.",
        "login-unauthorized-error": "There was an error in the email address or username/password entered.",
        "login-user-blocked-error": "Your account has reached the maximum number of failed login attempts and has been temporarily blocked. Please <a href=\"{url}\">contact us</a> to unblock it.",
        "login-user-temporary-blocked-error": "You have reached too many failed login attempts. Please try again later or <a href=\"{url}\">reset your password here</a>."
      },
      "filters": {
        "divisions": {
          "empty": "No class",
          "title": "Classes"
        },
        "loading": "Loading..."
      },
      "form": {
        "mandatory-all-fields": "All fields are required.",
        "mandatory-fields": " indicates a required field",
        "mandatory-fields-title": "required"
      },
      "fullname": "{firstName} {lastName}",
      "help-form": "https://pix.org/en-gb/contact-form",
      "home-page": "Pix Orga home page",
      "loading": "Loading",
      "pagination": {
        "action": {
          "next": "Go to next page",
          "previous": "Go to previous page",
          "select-page-size": "Select the number of items by page"
        },
        "page-info": "{start, number}-{end, number} of {total, plural, =0 {0 items} =1 {1 item} other {{total, number} items}}",
        "page-number": "Page {current, number} / {total, number}",
        "page-results": "{total, plural, =0 {0 items} =1 {1 item} other {{total, number} items}}",
        "result-by-page": "See"
      },
      "percentage": "{value, number, ::percent}",
      "target-profile-details": {
        "results": {
          "common": "Success rate in ",
          "percent": "percentage",
          "star": "stars"
        },
        "subjects": "Topics: {value, number}",
        "thematic-results": "Thematic results: {value, number}"
      }
    },
    "components": {
      "group": {
        "SCO": "Class",
        "SUP": "Group"
      },
      "participation-status": {
        "SHARED-ASSESSMENT": "Submitted results",
        "SHARED-PROFILES_COLLECTION": "Submitted profile",
        "STARTED-ASSESSMENT": "In progress",
        "TO_SHARE-ASSESSMENT": "Pending results",
        "TO_SHARE-PROFILES_COLLECTION": "Pending profile"
      }
    },
    "current-lang": "en",
    "navigation": {
      "assessment-individual-results": {
        "aria-label": "Individual assessment resultas navigation"
      },
      "campaign-page": {
        "aria-label": "Campaign navigation"
      },
      "credits": {
        "number": "{count, plural, =0 {0 credits} =1 {1 credit} other {{count, number} credits}}",
        "tooltip-text": "The number of credits displayed is the number of credits acquired by the organisation and currently valid (regardless of their activation). For more information, please contact us at the following address: <a href=mailto:support@ecriplus.fr>support@ecriplus.fr</a>"
      },
      "footer": {
        "a11y": "Accessibility",
        "aria-label": "Footer navigation",
        "copyrights": "©",
        "legal-notice": "Legal notice",
        "pix": "écri+"
      },
      "main": {
        "aria-label": "Main navigation",
        "campaigns": "Campaigns",
        "certifications": "Certifications",
        "documentation": "Documentation",
        "organization-participants": "Participants",
        "sco-organization-participants": "Students",
        "sup-organization-participants": "Students",
        "team": "Team"
      },
      "pix-orga": "écri+éval",
      "team-members": {
        "aria-label": "Team members navigation"
      },
      "user-logged-menu": {
        "button": "Open user menu",
        "logout": "Log out"
      }
    },
    "pages": {
      "assessment-individual-results": {
        "badges": "Thematic results",
        "progression": "Progression",
        "result": "Result",
        "stages": {
          "label": "Success rate",
          "value": "{count} out of {total} stars"
        },
        "tab": {
          "results": "Results",
          "review": "Review"
        },
        "table": {
          "column": {
            "competences": "Competences ({count, plural, =0 {-} other {{count}}})",
            "results": {
              "label": "Results",
              "tooltip": "<span class=\"screen-reader-only\">This participant has validated</span> {result, number, ::percent} <span class=\"screen-reader-only\">of the competence {competence}.</span>"
            }
          },
          "empty": "Pending results",
          "row-title": "Competence",
          "title": "Results by competence"
        },
        "title": "Results of {firstName} {lastName}"
      },
      "campaign": {
        "actions": {
          "export-results": "Export the results (.csv)",
          "unarchive": "Unarchive the campaign"
        },
        "archived": "Archived campaign",
        "code": "Code",
        "copy": {
          "code": {
            "default": "Copy code",
            "success": "Copied!"
          },
          "link": {
            "default": "Copy direct link",
            "success": "Copied!"
          }
        },
        "created-by": "Owner",
        "created-on": "Created on",
        "empty-state": "No participants yet! Share with them the following link to join this campaign.",
        "name": "Name of the campaign",
        "tab": {
          "activity": "Activity",
          "results": "Results ({count, number})",
          "review": "Review",
          "settings": "Settings"
        }
      },
      "campaign-activity": {
        "actions": {
          "delete": "Delete"
        },
        "delete-participation-modal": {
          "actions": {
            "cancel": "No",
            "confirmation": "Yes, delete"
          },
          "error": "An error occurred while removing this participation.",
          "success": "The participation has been successfully deleted",
          "text": "You are about to delete the participation of <strong>{firstName} {lastName}</strong>, it will no longer be visible nor included in the statistics of the campaign.",
          "title": "Delete this participation ?",
          "warning": "The participant will be able to complete their customised test but will no longer be able to submit their results. They will also not be able to participate in the campaign again."
        },
        "table": {
          "column": {
            "delete": "Delete participation",
            "first-name": "First name",
            "last-name": "Last name",
            "status": "Status"
          },
          "delete-button-label": "Delete participation",
          "empty": "No participants",
          "row-title": "Participant",
          "see-results": "See results of {firstName} {lastName}",
          "title": "List of participants"
        },
        "title": "Activity"
      },
      "campaign-creation": {
        "actions": {
          "create": "Create a campaign",
          "delete": "Delete"
        },
        "external-id-label": {
          "label": "external user ID label",
          "question-label": "Do you need an external user ID?",
          "suggestion": "Example : \"Student Number\" or \"Email address\" *"
        },
        "landing-page-text": {
          "label": "Text to display on the starting page"
        },
        "legal-warning": "*In accordance with the French law governing computer technology and freedoms (“Informatique et Libertés”), and as data controller, please be careful not to ask for significant or identifying personal data unless it is absolutely necessary. Asking for social security numbers, as well as any sensitive data, is strictly prohibited.",
        "multiple-sendings": {
          "info": "If you choose to allow multiple submissions, the participants will be able to submit their profile several times by re-entering the campaign code. Within écri+éval you will find the latest submitted profile.",
          "info-title": "Multiple submissions",
          "question-label": "Do you want to allow participants to submit their profile more than once?"
        },
        "name": {
          "label": "Name of the campaign"
        },
        "no": "No",
        "owner": {
          "clear-label": "Clear the selection",
          "collapse-label": "Close the dropdown list",
          "expand-label": "Open the dropdown list",
          "info": "The campaign owner, along with the organisation administrators, are the only ones who can modify or archive this campaign.",
          "label": "Campaign owner",
          "placeholder": "Owner’s first and last name",
          "search-placeholder": "Search for an owner",
          "title": "Campaign owner"
        },
        "purpose": {
          "assessment": "Assess participants",
          "assessment-info": "An assessment campaign tests participants on specific topics.",
          "label": "What is the purpose of your campaign?",
          "profiles-collection": "Collect the participants' écri+ profiles",
          "profiles-collection-info": "A profile collection campaign retrieves the participants’ écri+ profile: their level for each competence and their écri+ score."
        },
        "tags": {
          "COMPETENCES": "ecri+ Competences",
          "CUSTOM": "Created for you",
          "DISCIPLINE": "Schools",
          "OTHER": "Other",
          "PREDEFINED": "Ready-made",
          "SUBJECT": "Subject"
        },
        "tags-title": "Filter:",
        "target-profiles-category-label": "Filter customised test by category",
        "target-profiles-category-placeholder": "Categories",
        "target-profiles-label": "Select a customised test",
        "target-profiles-list-label": "What would you like to test?",
        "target-profiles-search-placeholder": "Search by name",
        "test-title": {
          "label": "Title of the customised test"
        },
        "title": "Create a campaign",
        "yes": "Yes"
      },
      "campaign-individual-results": {
        "shared-date": "Sent on",
        "start-date": "Started on"
      },
      "campaign-individual-review": {
        "title": "Review for {firstName} {lastName}"
      },
      "campaign-modification": {
        "actions": {
          "edit": "Edit"
        },
        "campaign-modification-success-message": "The changes have been successfully saved.",
        "campaign-name": "Name of the campaign",
        "landing-page-text": "Text to display on the starting page",
        "owner": {
          "info": "The campaign owner, along with the organisation administrators, are the only ones who can modify or archive this campaign.",
          "label": "Owner of the campaign",
          "placeholder": "Owner’s first and last name",
          "title": "Owner of the campaign"
        },
        "personalised-test-title": "Title of the personalised test",
        "title": "Edit a campaign"
      },
      "campaign-results": {
        "average": "Average results",
        "filters": {
          "actions": {
            "clear": "Clear filters"
          },
          "aria-label": "Filters on participations",
          "participations-count": "{count, plural, =0 {0 participants} =1 {1 participant} other {{count} participants}}",
          "title": "Filters",
          "type": {
            "badges": "Thematic results",
            "divisions": {
              "placeholder": "Classes"
            },
            "groups": {
              "empty": "No group",
              "title": "Groups"
            },
            "search": {
              "placeholder": "Lastname, firstname",
              "title": "Search by lastname and firstname"
            },
            "stages": "Success rate",
            "status": {
              "empty": "All statuses",
              "title": "Status"
            }
          }
        },
        "result": "Result",
        "table": {
          "column": {
            "badges": "Thematic results",
            "first-name": "First name",
            "last-name": "Last name",
            "results": {
              "label": "Success rate",
              "on-hold": "Pending",
              "under-test": "Test in progress",
              "value": "{percentage, number, ::percent}"
            }
          },
          "empty": "No participants",
          "row-title": "Participant",
          "title": "List of submitted results"
        },
        "tested-items": "Items assessed",
        "title": "Results",
        "validated-items": "Items successfully completed"
      },
      "campaign-review": {
        "description": "According to the target profile selected and the results of your campaign, écri+ recommends focusing on the following topics, sorted by relevance {bubble}.",
        "sub-table": {
          "column": {
            "source": {
              "value": "By {source}"
            }
          },
          "row-title": "Tutorial",
          "title": "{count, plural, =1 {1 tutorial} other{{count} tutorials}} recommended by the écri+ community"
        },
        "sub-title": "Recommendations of topics to focus on",
        "table": {
          "analysis": {
            "caption": "Table of topics to work on, sorted by relevance. Some have additional columns showing the number of tutorials that exist related to the topic and how to access these tutorials",
            "column": {
              "relevance": {
                "ariaLabelDefaultSort": "The array is not yet sorted by relevance. Click to sort ascending.",
                "ariaLabelSortDown": "The array is sorted by descending relevance. Click to sort ascending.",
                "ariaLabelSortUp": "The array is sorted by ascending relevance. Click to sort descending.",
                "label": "Relevance"
              },
              "subjects": "Topics ({count, plural, =0 {-} other {{count}}})",
              "tutorial": {
                "aria-label": "Show all tutorials"
              },
              "tutorial-count": {
                "aria-label": "Number of recommended tutorials",
                "value": "{count, plural, =1 {1 tutorial} other {{count} tutorials}}"
              }
            },
            "recommendations": {
              "moderately-recommended": "Moderately recommended",
              "recommended": "Recommended",
              "strongly-recommended": "Strongly recommended",
              "very-strongly-recommended": "Very strongly recommended"
            },
            "row-title": "Topic",
            "title": "Review by topic"
          },
          "competences": {
            "column": {
              "competences": "Competences ({count})",
              "results": {
                "label": "Results",
                "tooltip": "<span class=\"screen-reader-only\">Participants have validated</span> {result} % <span class=\"screen-reader-only\">of the competence {competence}.</span>"
              }
            },
            "row-title": "Competence",
            "title": "Results by competence"
          },
          "empty": "Pending results"
        },
        "title": "Review"
      },
      "campaign-settings": {
        "actions": {
          "archive": "Archive",
          "edit": "Edit"
        },
        "campaign-type": {
          "assessment": "Assessment campaign",
          "profiles-collection": "Profiles collection campaign",
          "title": "Type of campaign"
        },
        "direct-link": "Direct link",
        "external-user-id-label": "External user ID label",
        "landing-page-text": "Text to display on the starting page",
        "multiple-sendings": {
          "status": {
            "disabled": "No",
            "enabled": "Yes"
          },
          "title": "Multiple Sendings",
          "tooltip": {
            "aria-label": "Description of a multiple sendings campaing",
            "text-multiple-sendings-disabled": "If the multiple submissions is activated, the participants will be able to submit their profile several times by re-entering the campaign code. Within écri+éval the latest submitted profile will be displayed.",
            "text-multiple-sendings-enabled": "The participants will be able to submit their profile several times by re-entering the campaign code. Within écri+éval you will find the latest submitted profile."
          }
        },
        "personalised-test-title": "Title of the customised test",
        "target-profile": {
          "title": "Customised test",
          "tooltip": "Customised test's description"
        },
        "title": "Settings"
      },
      "campaigns-list": {
        "action": {
          "archived": {
            "label": "Archived"
          },
          "create": "Create a campaign",
          "ongoing": {
            "label": "Ongoing"
          }
        },
        "filter": {
          "by-name": "Search for a campaign",
          "by-owner": "Search for an owner",
          "clear": "Clear filters",
          "legend": "Filtres pour le tableau des campagnes, des champs de saisie permettent de filtrer le tableau par le nom de la campagne et par nom du propriétaire. Des boutons permettent de filtrer les campagnes actives et archivées.",
          "results": "{total, plural, =0 {0 campaigns} =1 {1 campaign} other {{total, number} campaigns}}",
          "title": "Filters"
        },
        "navigation": "Navigation in the Campaign section",
        "no-campaign": "No campaign. To start, click on 'Create a campaign'.",
        "table": {
          "column": {
            "campaign": "Name of the campaign",
            "created-by": "Owner",
            "created-on": "Created on",
            "participants": "Participants",
            "results": "Results submitted"
          },
          "empty": "No campaign",
          "row-title": "Campaign"
        },
        "tabs": {
          "all-campaigns": "All the campaigns",
          "my-campaigns": "My campaigns"
        },
        "title": "Campaigns"
      },
      "certifications": {
        "description": "Please select the class for which you would like to export the certification results (.csv) or download certificates (.pdf).",
        "documentation-link": "https://ecriplus.fr",
        "documentation-link-label": "Interpreting the results.",
        "documentation-link-notice": "Follow this link to find indications on how to interpret the results: ",
        "download-attestations-button": "Download attestations",
        "download-button": "Export the results",
        "errors": {
          "invalid-division": "The class {selectedDivision} does not exist.",
          "no-certificates": "No certificates for the class {selectedDivision}.",
          "no-results": "No certification results for the class {selectedDivision}."
        },
        "no-students-imported-yet": "In this tab, you will find the certification results and certificates of the students. You will first need to import the students database of your school.",
        "select-label": "Class",
        "title": "Certification results"
      },
      "login": {
        "title": "Log in"
      },
      "login-form": {
        "active-or-retrieve": "Activate or retrieve your écri+éval space",
        "email": "Email address",
        "errors": {
          "empty-password": "Your password can't be empty.",
          "invalid-email": "Your email address is invalid.",
          "should-change-password": "You currently have a temporary password. <a href=\"{url}\">Reset your password here</a>.",
          "status": {
            "403": "Access to this écri+éval space is limited to invited members. Each écri+éval space is managed by an administrator specific to the organisation using it. Please contact your administrator to get invited.",
            "409": "This invitation has been already accepted or cancelled."
          }
        },
        "forgot-password": "Forgot your password?",
        "forgotten-password-url": "https://app.tests.ecriplus.fr/mot-de-passe-oublie?lang=en",
        "invitation-already-accepted": "This invitation has already been accepted. Please log in or contact the administrator of your écri+éval space.",
        "invitation-was-cancelled": "This invitation is no longer valid. Please contact the administrator of your écri+éval space.",
        "is-only-accessible": "écri+éval is only accessible to invited members. Please contact your écri+éval administrator in order to be invited.",
        "login": "Log in",
        "only-for-admin": "only for school administrations",
        "password": "Password"
      },
      "login-or-register": {
        "login-form": {
          "button": "Log in",
          "title": "Already have an account?"
        },
        "register-form": {
          "button": "Sign up",
          "errors": {
            "default": "The service is temporarily unavailable. Please try again later.",
            "email-already-exists": "This email address is already registered, please login."
          },
          "fields": {
            "button": {
              "label": "Sign up"
            },
            "cgu": {
              "accept": "I agree to the",
              "and": "and",
              "aria-label": "Accept the terms of use of the Pix platform",
              "data-protection-policy": "personal data protection policy",
              "error": "You must agree to the ecri+ terms of use and personal data protection policy to create an account.",
              "terms-of-use": "ecri+ terms of use"
            },
            "email": {
              "error": "Please enter a valid email address.",
              "label": "Email address"
            },
            "first-name": {
              "error": "Please enter a first name.",
              "label": "First name"
            },
            "last-name": {
              "error": "Please enter a last name.",
              "label": "Last name"
            },
            "password": {
              "error": "Your password must contain at least 8 characters and include at least one uppercase letter, one lowercase letter and one number.",
              "label": "Password"
            }
          },
          "title": "Sign up"
        },
        "title": "You have been invited to join the organisation {organizationName}"
      },
      "organization-learner": {
        "activity": {
          "assessment-summary": "Assessment participations summary",
          "cards": {
            "shared": "Submitted: {count}",
            "started": "In progress: {count}",
            "title": {
              "assessment": "Assessments",
              "profiles-collection": "Profile collections"
            },
            "to-share": "Pending: {count}"
          },
          "empty-state": "No activity yet. As soon as {organizationLearnerFirstName} {organizationLearnerLastName} participates in a campaign, you will be able to follow their progress here.",
          "participation-list": {
            "table": {
              "column": {
                "campaign-name": "Campaign",
                "campaign-type": "Type",
                "created-at": "Started on",
                "shared-at": "Sent on",
                "status": "Status"
              },
              "row-title": "Participation"
            },
            "type": {
              "ASSESSMENT": "Assessment",
              "PROFILES_COLLECTION": "Profiles collection"
            }
          },
          "participations-title": "Participations",
          "profile-collection-summary": "Profile collection participations summary",
          "title": "Activity"
        },
        "header": {
          "aria-label-title": "Participant's name"
        }
      },
      "organization-participant-activity": {
        "title": "Participant activity"
      },
      "organization-participants": {
        "empty-state": {
          "action": "See my campaigns",
          "call-to-action": "Share with them a link to join an existing campaign or create a new campaign.",
          "message": "No participants yet!"
        },
        "filters": {
          "actions": {
            "clear": "Clear filters"
          },
          "aria-label": "Filters on participants",
          "participations-count": "{count, plural, =0 {0 participants} =1 {1 participant} other {{count} participants}}",
          "title": "Filters",
          "type": {
            "certificability": {
              "label": "Search by certificability",
              "placeholder": "Search by certificability"
            },
            "fullName": {
              "placeholder": "Lastname, firstname",
              "title": "Search by lastname and firstname"
            }
          }
        },
        "page-title": "Participants",
        "table": {
          "column": {
            "first-name": "First name",
            "is-certifiable": {
              "label": "Eligibility for certification",
              "tooltip": {
                "aria-label": "How to know if a participant is eligible for certification and what does it mean?",
                "content": "To know if a participant is eligible for certification (meaning they have reached the level 1 in at least 5 different competences), their Pix profile needs to be submitted through a profile collection campaign. If the participant has already submitted their profile, the date and status of the last submission are displayed."
              }
            },
            "last-name": "Last name",
            "latest-participation": "Latest participation",
            "participation-count": {
              "ariaLabelDefaultSort": "The array is not yet sorted by number of participations. Click to sort ascending.",
              "ariaLabelSortDown": "The array is sorted by descending number of participations. Click to sort ascending.",
              "ariaLabelSortUp": "The array is sorted by ascending number of participations. Click to sort descending.",
              "label": "Number of participations"
            }
          },
          "empty": "No participants",
          "row-title": "Participant"
        },
        "title": "{count, plural, =0 {Participants} =1 {Participant ({count})} other {Participants ({count})}}"
      },
      "participants-list": {
        "latest-participation-information-tooltip": {
          "aria-label": "Latest participation's information",
          "campaign-ASSESSMENT-type": "Assessment",
          "campaign-PROFILES_COLLECTION-type": "Profiles collection",
          "campaign-name": "Campaign:",
          "campaign-status": "Status: ",
          "campaign-type": "Type: ",
          "participation-SHARED-status": "Submitted",
          "participation-STARTED-status": "In progress",
          "participation-TO_SHARE-status": "Pending"
        }
      },
      "preselect-target-profile": {
        "download": "Download topics selection ({ numberOfTubesSelected }) (JSON, { fileSize }kB)",
        "download-filename": "topic-selection-{ organizationName }-{ date }.json",
        "no-tube-selected": "Download topics selection (JSON, { fileSize }kB)",
        "table": {
          "caption": "Topic selection",
          "column": {
            "action": "Select",
            "mobile": "Mobile compliant",
            "name": "Topic name",
            "tablet": "Tablet compliant",
            "theme-name": "Theme name"
          },
          "is-responsive": "yes",
          "not-responsive": "no",
          "row-title": "Topic"
        },
        "title": "Topic selection"
      },
      "profiles-individual-results": {
        "certifiable": "Eligible for certification",
        "competences-certifiables": "COMP. ELIGIBLE FOR CERTIFICATION",
        "pix": "écri+",
        "pix-score": "{score, number}",
        "table": {
          "column": {
            "level": "Level",
            "pix-score": "écri+ score",
            "skill": "Competence"
          },
          "empty": "Pending results",
          "row-title": "Competence",
          "title": "Results by competence"
        },
        "title": "Profile of {firstName} {lastName}"
      },
      "profiles-list": {
        "table": {
          "column": {
            "certifiable": "Eligible for certification",
            "competences-certifiables": "Competences eligible for certification",
            "first-name": "First name",
            "last-name": "Last name",
            "pix-score": {
              "label": "écri+ score",
              "value": "{score, number}"
            },
            "sending-date": {
              "label": "Sent on",
              "on-hold": "Pending"
            }
          },
          "empty": "No profiles yet",
          "row-title": "Profile",
          "title": "List of submitted profiles"
        },
        "title": "Submitted profiles"
      },
      "sco-organization-participant-activity": {
        "title": "Student activity"
      },
      "sco-organization-participants": {
        "actions": {
          "import-file": {
            "file-type-separator": " or ",
            "label": "Import ({types})"
          },
          "manage-account": "Manage the account",
          "show-actions": "Show actions"
        },
        "connection-types": {
          "email": "Email address",
          "empty": "–",
          "identifiant": "Username",
          "mediacentre": "Mediacentre",
          "none": "None"
        },
        "filter": {
          "actions": {
            "clear": "Clear filters"
          },
          "aria-label": "Filters on students",
          "certificability": {
            "label": "Search by certificability",
            "placeholder": "Search by certificability"
          },
          "division": {
            "aria-label": "Enter a class",
            "empty": "No class",
            "label": "Search by class"
          },
          "first-name": {
            "aria-label": "Enter a first name",
            "label": "Search by first name"
          },
          "last-name": {
            "aria-label": "Enter a last name",
            "label": "Search by last name"
          },
          "login-method": {
            "aria-label": "Search by log in method",
            "empty-option": "login method"
          },
          "search": {
            "aria-label": "Search by lastname and firstname",
            "label": "Lastname, firstname"
          },
          "students-count": "{count, plural, =0 {0 students} =1 {1 student} other {{count} students}}",
          "title": "Filters"
        },
        "import": {
          "error-wrapper": "<div>Import has failed.<br/><strong>{message}</strong><br/> Please check or edit your file and try to import it again.</div>",
          "global-error": "<div>Import has failed.<br/>Please try again or contact us through <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"mailto:support@ecriplus.fr\">an email</a>.</div>",
          "global-success": "The list has been successfully imported."
        },
        "manage-authentication-method-modal": {
          "authentication-methods": "Log in methods",
          "copied": "Copied!",
          "error": {
            "default": "An error occurred, our teams are working on finding a solution. Please try again later.",
            "unexpected": "Something went wrong. Please try again."
          },
          "section": {
            "add-username": {
              "button": "Add a username",
              "label": "Add a username log in method"
            },
            "email": {
              "copy": "Copy the email address",
              "label": "Email address"
            },
            "mediacentre": {
              "label": "Mediacentre"
            },
            "password": {
              "copy": "Copy the temporary password",
              "label": "New temporary password",
              "warning-1": "Send this new temporary password to your student.",
              "warning-2": "Your student will be able to log in with the temporary password.",
              "warning-3": "Your student will choose a new password."
            },
            "reset-password": {
              "button": "Reset password",
              "warning": "Resetting will delete the student’s current password."
            },
            "username": {
              "copy": "Copy the username",
              "label": "Username"
            }
          },
          "title": "Managing the student's écri+ account"
        },
        "no-participants": "No students yet!",
        "no-participants-action": "The administrator must import the students database by clicking on the import button.",
        "page-title": "Students",
        "table": {
          "column": {
            "date-of-birth": "Date of birth",
            "division": "Class",
            "first-name": "First name",
            "is-certifiable": {
              "eligible": "Eligible",
              "label": "Eligibility for certification",
              "non-eligible": "Non-eligible",
              "not-available": "Not available",
              "tooltip": {
                "aria-label": "How to know if a student is eligible for certification and what does it mean?",
                "content": "To know if a student is eligible for certification (meaning they have reached the level 1 in at least 5 different competences), their Pix profile needs to be submitted through a profile collection campaign. If the student has already submitted their profile, the date and status of the last submission are displayed."
              }
            },
            "last-name": "Last name",
            "last-participation-date": "Latest participation",
            "login-method": "Log in method(s)",
            "participation-count": {
              "ariaLabelDefaultSort": "The array is not yet sorted by number of participations. Click to sort ascending.",
              "ariaLabelSortDown": "The array is sorted by descending number of participations. Click to sort ascending.",
              "ariaLabelSortUp": "The array is sorted by ascending number of participations. Click to sort descending.",
              "label": "Number of participations"
            }
          },
          "description": "Table of students, sorted by name. For students who have provided an authentication method, you can manage the allowed login modes and reset the student's password through a menu in 'Actions' column",
          "empty": "No students.",
          "row-title": "Student"
        },
        "title": "{count, plural, =0 {Students} =1 {Student ({count})} other {Students ({count})}}"
      },
      "sup-organization-participant-activity": {
        "title": "Student activity"
      },
      "sup-organization-participants": {
        "actions": {
          "download-template": "Download the template",
          "edit-student-number": "Edit the student number",
          "import-file": "Import",
          "show-actions": "Show actions"
        },
        "edit-student-number-modal": {
          "actions": {
            "update": "Update"
          },
          "form": {
            "error": "The student number can't be empty",
            "new-student-number-label": "New student number",
            "student-number": "{firstName} {lastName}'s current student number is: ",
            "success": "{firstName} {lastName}'s student number has been successfully edited."
          },
          "title": "Edit a student number"
        },
        "empty-state": {
          "no-participants": "No students yet!",
          "no-participants-action": "The administrator must import the students database by clicking on the import button."
        },
        "filter": {
          "actions": {
            "clear": "Clear filters"
          },
          "aria-label": "Filters on students",
          "certificability": {
            "label": "Search by certificability",
            "placeholder": "Search by certificability"
          },
          "group": {
            "aria-label": "Enter a group",
            "empty": "No group",
            "label": "Search by group"
          },
          "search": {
            "aria-label": "Search by lastname and firstname",
            "label": "Lastname, firstname"
          },
          "student-number": {
            "aria-label": "Enter a student number",
            "label": "Search by student number"
          },
          "students-count": "{count, plural, =0 {0 students} =1 {1 student} other {{count} students}}",
          "title": "Filters"
        },
        "page-title": "Students",
        "table": {
          "column": {
            "date-of-birth": "Date of birth",
            "first-name": "First name",
            "group": "Groups",
            "is-certifiable": {
              "label": "Certificabilité",
              "tooltip": {
                "aria-label": "How to know if a student is eligible for certification and what does it mean?",
                "content": "To know if a student is eligible for certification (meaning they have reached the level 1 in at least 5 different competences), their Pix profile needs to be submitted through a profile collection campaign. If the student has already submitted their profile, the date and status of the last submission are displayed."
              }
            },
            "last-name": "Last name",
            "last-participation-date": "Latest participation",
            "participation-count": {
              "ariaLabelDefaultSort": "The array is not yet sorted by number of participations. Click to sort ascending.",
              "ariaLabelSortDown": "The array is sorted by descending number of participations. Click to sort ascending.",
              "ariaLabelSortUp": "The array is sorted by ascending number of participations. Click to sort descending.",
              "label": "Number of participations"
            },
            "student-number": "Student number"
          },
          "description": "Table of students, sorted by name.You can edit the student's number through a menu in an additional column",
          "empty": "No students.",
          "row-title": "Student"
        },
        "title": "{count, plural, =0 {Students} =1 {Student ({count})} other {Students ({count})}}"
      },
      "sup-organization-participants-import": {
        "actions": {
          "add": {
            "details": "Add a list of students to the one already imported and/or edit the information of students already imported.",
            "label": "Add students",
            "title": "Add / Edit"
          },
          "replace": {
            "details": "Import a new list of students in replacement of the existing one.",
            "label": "Overwrite list",
            "title": "Overwrite"
          }
        },
        "description": "What type of import would you like to use?",
        "error-wrapper": "<div>Import has failed.<br/><strong>{message}</strong><br/> Please edit your file and try to import it again.</div>",
        "global-error": "<div>Import has failed.<br/>Please try again or contact us through <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"mailto:support@ecriplus.fr\">an email</a></div>",
        "global-success": "The list has been successfully imported.",
        "global-success-with-warnings": "<div>The list has been successfully imported.<br/><br/>However some values were not recognised:<br/>{warnings}<br/><br/>They have been replaced by \"Not recognised\". If you need other values, please contact us at <a href=\"mailto:support@ecriplus.fr\">support@ecriplus.fr</a></div>",
        "supported-formats": "(Supported format: csv)",
        "title": "Import students",
        "warnings": {
          "diploma": "Diplomas unknown: {diplomas}; ",
          "study-scheme": "Study schemes unknown: {studySchemes}; "
        }
      },
      "team-invitations": {
        "cancel-invitation": "Delete this invitation",
        "invitation-cancelled-succeed-message": "The invitation has been deleted.",
        "invitation-resent-succeed-message": "The invitation has been resent.",
        "resend-invitation": "Resend the invitation",
        "table": {
          "column": {
            "email-address": "Email address",
            "pending-invitation": "Pending invitation"
          },
          "row": {
            "aria-label": "Pending invitation"
          }
        },
        "title": "Team invitations"
      },
      "team-list": {
        "add-member-button": "Invite a member",
        "tabs": {
          "invitation": "Invitations ({count, plural, =0 {-} other {{count}}})",
          "member": "Members ({count, plural, =0 {-} other {{count}}})"
        },
        "title": "Team"
      },
      "team-members": {
        "actions": {
          "edit-organization-membership-role": "Change role",
          "manage": "Manage",
          "remove-membership": "Remove",
          "save": "Save",
          "select-role": {
            "label": "Select a role",
            "options": {
              "admin": "Administrator",
              "member": "Member"
            }
          }
        },
        "notifications": {
          "change-member-role": {
            "error": "An error occurred while changing this member's role.",
            "success": "The role has been changed."
          },
          "remove-membership": {
            "error": "An error occurred while removing this member.",
            "success": "{memberFirstName} {memberLastName} has successfully been removed from your écri+éval team."
          }
        },
        "remove-membership-modal": {
          "actions": {
            "remove": "Yes, remove the member"
          },
          "message": "{memberFirstName} {memberLastName} will no longer have access to this écri+éval space.<br>The campaigns created by this member will still be accessible to the rest of the team.",
          "title": "Are you sure you want to remove this member?"
        },
        "table": {
          "column": {
            "first-name": "First name",
            "last-name": "Last name",
            "organization-membership-role": "Role"
          },
          "empty": "No members yet",
          "row-title": "Member"
        },
        "title": "Team members"
      },
      "team-new": {
        "email-requirement": "Enter here the email address of the member you want to invite.",
        "errors": {
          "default": "The service is temporarily unavailable. Please try again later.",
          "status": {
            "400": "The email address format is invalid.",
            "404": "This email address doesn't match any écri+ user.",
            "412": "This member has already been added.",
            "500": "Something went wrong. Please try again."
          }
        },
        "invited-members": "By clicking on the link provided in the invitation, the invited members will be able to create an account or log in with an existing écri+ account.",
        "several-email-requirement": "Invite several members by separating the email addresses with commas.",
        "success": {
          "invitation": "An invitation has been sent to the email address {email}.",
          "multiple-invitations": "An invitation has been sent to the listed email addresses."
        },
        "title": "Invite a member"
      },
      "team-new-item": {
        "input-label": "Email address(es)",
        "invite-button": "Invite"
      },
      "terms-of-service": {
        "accept": "I accept the terms and conditions",
        "title": "Terms and conditions of use"
      }
    }
  }], ["fr", {
    "api-error-messages": {
      "campaign-creation": {
        "custom-landing-page_too_long": "Le texte de présentation de la campagne est trop long.",
        "external-user-id-required": "Veuillez préciser le libellé du champ qui sera demandé à vos participants au démarrage du parcours.",
        "name-required": "Veuillez donner un nom à votre campagne.",
        "owner_not_in_organization": "Le propriétaire ne fait pas parti de l'organisation.",
        "purpose-required": "Veuillez choisir l’objectif de votre campagne : Évaluation ou Collecte de profils.",
        "target-profile-required": "Veuillez sélectionner un parcours pour votre campagne.",
        "title_too_long": "Le titre de la campagne est trop long."
      },
      "default": "Le service est momentanément indisponible. Veuillez réessayer ultérieurement.",
      "edit-student-number": {
        "student-number-exists": "Le numéro étudiant saisi est déjà utilisé par l’étudiant {firstName} {lastName}"
      },
      "global": "Une erreur est survenue. Veuillez réessayer ultérieurement.",
      "or-separator": " ou ",
      "student-csv-import": {
        "bad-csv-format": "Le fichier doit être au format csv avec séparateur virgule ou point-virgule.",
        "encoding-not-supported": "Encodage du fichier non supporté.",
        "field-bad-values": "Ligne {line} : Le champ “{field}” doit être “{valids}”.",
        "field-date-format": "Ligne {line} : Le champ “{field}” doit être au format jj/mm/aaaa.",
        "field-email-format": "Ligne {line} : Le champ “{field}” doit être une adresse email valide.",
        "field-length": "Ligne {line} : Le champ “{field}” doit faire {limit} caractères.",
        "field-max-length": "Ligne {line} : Le champ “{field}” doit être inférieur à {limit} caractères.",
        "field-min-length": "Ligne {line} : Le champ “{field}” doit être d’au moins {limit} caractères.",
        "field-required": "Ligne {line} : Le champ “{field}” est obligatoire.",
        "header-required": "La colonne “{field}” est obligatoire.",
        "header-unknown": "Les entêtes de colonnes doivent être identiques à celles du modèle.",
        "identifier-unique": "Ligne {line} : Le champ “{field}” de cette ligne est présent plusieurs fois dans le fichier.",
        "insee-code-invalid": "Ligne {line} : Le champ “{field}” n'est pas au format INSEE.",
        "payload-too-large": "La taille du fichier doit être inférieure à {maxSize}Mo.",
        "student-number-format": "Ligne {line} : Le champ “{field}” ne doit pas avoir de caractères spéciaux.",
        "student-number-unique": "Ligne {line} : Le champ “{field}” doit être unique au sein du fichier."
      },
      "student-xml-import": {
        "birth-city-code-required-for-french-student": "Le champ code commune de naissance est obligatoire pour chaque élève née en France.",
        "empty": "Les élèves doivent posséder un INE ainsi qu'une classe.",
        "encoding-not-supported": "Encodage du fichier non supporté.",
        "ine-required": "Le champ INE est obligatoire pour chaque élève.",
        "ine-unique": "L’INE {nationalStudentId} est présent plusieurs fois dans le fichier pour plusieurs élèves.",
        "invalid-file": "Le fichier n’est pas conforme.",
        "invalid-file-extension": "Extension {fileExtension} non supportée.",
        "payload-too-large": "La taille du fichier doit être inférieure à {maxSize}Mo.",
        "sex-code-required": "Le champ sexe est obligatoire pour chaque élève.",
        "uai-mismatched": "L’UAI du fichier SIECLE ne correspond pas à celui de votre établissement."
      }
    },
    "banners": {
      "campaigns": {
        "message": "'<strong>'Parcours de rentrée 2022 :'</strong>' N’oubliez pas de créer les campagnes de rentrée et de diffuser les codes aux élèves avant la Toussaint. '<'a href={documentationLink} class=\"{linkClasses}\" target=\"_blank\" rel=\"noopener noreferrer\"'>'Plus d’info'</a>'"
      },
      "import": {
        "message": "'<strong>'Rentrée 2022 :'</strong>' l’administrateur doit '<'a href=\"/eleves\" class=\"{linkClasses}\"'>'importer la base élèves'</a>' 2022 pour initialiser écri+éval. '<'a href={documentationLink} class=\"{linkClasses}\" target=\"_blank\" rel=\"noopener noreferrer\"'>'Plus d’info'</a>'"
      }
    },
    "cards": {
      "participants-average-results": {
        "information": "Retrouvez ici la moyenne des résultats de votre campagne. Ce nombre comprend l'ensemble des participants ayant fini leur parcours et envoyé leur résultat.",
        "title": "Résultat moyen"
      },
      "participants-average-stages": {
        "information": "Retrouvez ici le palier moyen de votre campagne. Ce nombre comprend l'ensemble des participants ayant fini leur parcours et envoyé leur résultat.",
        "title": "Palier moyen"
      },
      "participants-count": {
        "information": "Retrouvez ici le nombre de participants total de votre campagne. Ce nombre comprend l'ensemble des participants ayant saisi le code et commencé leur parcours ou leur envoi de profil.",
        "loader": "Chargement du total de participants",
        "title": "Total de participants"
      },
      "submitted-count": {
        "information": "Retrouvez ici les résultats envoyés par vos participants. Ce nombre comprend l’ensemble des participants ayant terminé et cliqué sur le bouton \"J'envoie mes résultats\" ou \"J'envoie mon profil\".",
        "loader": "Chargement des résultats ou profils reçus",
        "title": "Résultats reçus",
        "title-profiles": "Profils reçus"
      }
    },
    "charts": {
      "participants-by-day": {
        "labels-a11y": {
          "date": "Date",
          "shared": "Total des participants ayant envoyé leurs résultats",
          "shared-profile": "Total des participants ayant envoyé leurs profils",
          "started": "Total des participants"
        },
        "labels-legend": {
          "shared": "Résultats reçus",
          "shared-profile": "Profils reçus",
          "started": "Total des participants"
        },
        "loader": "Chargement de l'activité des participants",
        "title": "Activité des participants"
      },
      "participants-by-mastery-percentage": {
        "label-a11y": "De {from, number, ::percent} à {to, number, ::percent}: {count, plural, =0 {0 participant} =1 {1 participant} other {{count, number} participants}}",
        "loader": "Chargement de la répartition des participants par résultat",
        "title": "Répartition des participants par résultat",
        "tooltip": {
          "label": "Nombre de participants : {count}",
          "legend": "{from, number, ::percent} - {to, number, ::percent}",
          "title": "Résultats {legend}"
        }
      },
      "participants-by-stage": {
        "label-a11y": "{stage, plural, =0 {0 étoile} =1 {1 étoile} other {{stage, number} étoiles}} sur {totalStage}",
        "loader": "Chargement de la répartition des participants par paliers",
        "participants": "{count, plural, =0 {0 participant} =1 {1 participant} other {{count, number} participants}}",
        "percentage": "{percentage} %",
        "title": "Répartition des participants par paliers"
      },
      "participants-by-status": {
        "labels-a11y": {
          "completed": "{count, plural, =0 {0 participant} =1 {1 participant} other {{count, number} participants}} en attente d'envoi",
          "shared": "{count, plural, =0 {0 participant} =1 {1 participant} other {{count, number} participants}} ayant envoyés leurs résultats",
          "shared-profile": "{count, plural, =0 {0 participant} =1 {1 participant} other {{count, number} participants}} ayant envoyés leurs profils",
          "started": "{count, plural, =0 {0 participant} =1 {1 participant} other {{count, number} participants}} en cours de test"
        },
        "labels-legend": {
          "completed-assessment": "En attente d'envoi ({count})",
          "completed-assessment-tooltip": "En attente d’envoi : Ces participants ont terminé leur parcours mais n’ont pas encore envoyé leurs résultats.",
          "completed-profile": "En attente d'envoi ({count})",
          "completed-profile-tooltip": "En attente d’envoi : Ces participants n’ont pas encore envoyé leurs profils.",
          "shared": "Résultats reçus ({count})",
          "shared-profile": "Profils reçus ({count})",
          "shared-profile-tooltip": "Profils reçus : Ces participants ont envoyé leurs profils.",
          "shared-tooltip": "Résultats reçus : Ces participants ont terminé leur parcours et envoyé leurs résultats.",
          "started": "En cours ({count})",
          "started-tooltip": "En cours : Ces participants n’ont pas encore terminé leur parcours."
        },
        "labels-tooltip": {
          "completed-assessment": "En attente d'envoi : {percentage, number, ::percent .0}",
          "completed-profile": "En attente d'envoi : {percentage, number, ::percent .0}",
          "shared": "Résultats reçus : {percentage, number, ::percent .0}",
          "shared-profile": "Profils reçus : {percentage, number, ::percent .0}",
          "started": "En cours : {percentage, number, ::percent .0}"
        },
        "loader": "Chargement des proportions par statut",
        "title": "Statuts"
      }
    },
    "common": {
      "actions": {
        "back": "Retour",
        "cancel": "Annuler",
        "close": "Fermer",
        "global": "Actions"
      },
      "api-error-messages": {
        "bad-request-error": "Les données que vous avez soumises ne sont pas au bon format.",
        "gateway-timeout-error": "Le service subit des ralentissements. Veuillez réessayer ultérieurement.",
        "internal-server-error": "Une erreur interne est survenue, nos équipes sont en train de résoudre le problème. Veuillez réessayer ultérieurement.",
        "login-unauthorized-error": "L'adresse e-mail ou l'identifiant et/ou le mot de passe saisis sont incorrects.",
        "login-user-blocked-error": "Votre compte est bloqué car vous avez effectué trop de tentatives de connexion. Pour le débloquer, <a href=\"{url}\">contactez-nous</a>.",
        "login-user-temporary-blocked-error": "Vous avez effectué trop de tentatives de connexion. Réessayez plus tard ou cliquez sur <a href=\"{url}\">mot de passe oublié</a> pour le réinitialiser."
      },
      "filters": {
        "divisions": {
          "empty": "Aucune classe",
          "title": "Classes"
        },
        "loading": "Chargement..."
      },
      "form": {
        "mandatory-all-fields": "Tous les champs sont obligatoires.",
        "mandatory-fields": " indique un champ obligatoire",
        "mandatory-fields-title": "obligatoire"
      },
      "fullname": "{firstName} {lastName}",
      "help-form": "https://support.ecriplus.fr",
      "home-page": "Page d'accueil de écri+éval",
      "loading": "Chargement en cours",
      "pagination": {
        "action": {
          "next": "Aller à la page suivante",
          "previous": "Aller à la page précédente",
          "select-page-size": "Nombre d'élément à afficher par page"
        },
        "page-info": "{start, number}-{end, number} sur {total, plural, =0 {0 élément} =1 {1 élément} other {{total, number} éléments}}",
        "page-number": "Page {current, number} / {total, number}",
        "page-results": "{total, plural, =0 {0 élément} =1 {1 élément} other {{total, number} éléments}}",
        "result-by-page": "Voir"
      },
      "percentage": "{value, number, ::percent}",
      "target-profile-details": {
        "results": {
          "common": "Résultats affichés en ",
          "percent": "pourcentage",
          "star": "étoile"
        },
        "subjects": "Sujets : {value, number}",
        "thematic-results": "Résultats thématiques : {value, number}"
      }
    },
    "components": {
      "group": {
        "SCO": "Classe",
        "SUP": "Groupe"
      },
      "participation-status": {
        "SHARED-ASSESSMENT": "Résultats reçus",
        "SHARED-PROFILES_COLLECTION": "Profil reçu",
        "STARTED-ASSESSMENT": "En cours",
        "TO_SHARE-ASSESSMENT": "En attente d'envoi",
        "TO_SHARE-PROFILES_COLLECTION": "En attente d'envoi"
      }
    },
    "current-lang": "fr",
    "navigation": {
      "assessment-individual-results": {
        "aria-label": "Navigation de la section résultat d'une évaluation individuelle"
      },
      "campaign-page": {
        "aria-label": "Navigation de la section détails d'une campagne"
      },
      "credits": {
        "number": "{count, plural, =0 {0 crédit} =1 {1 crédit} other {{count, number} crédits}}",
        "tooltip-text": "Le nombre de crédits affichés correspond au nombre de crédits acquis par l’organisation et en cours de validité (indépendamment de leur activation). Pour plus d’information contactez-nous à l’adresse <a href=mailto:support@ecriplus.fr>support@ecriplus.fr</a>"
      },
      "footer": {
        "a11y": "Accessibilité : non conforme",
        "aria-label": "Navigation de pied de page",
        "copyrights": "©",
        "legal-notice": "Mentions légales",
        "pix": "écri+"
      },
      "main": {
        "aria-label": "Navigation principale",
        "campaigns": "Campagnes",
        "certifications": "Certifications",
        "documentation": "Documentation",
        "organization-participants": "Participants",
        "sco-organization-participants": "Élèves",
        "sup-organization-participants": "Étudiants",
        "team": "Équipe"
      },
      "pix-orga": "écri+éval",
      "team-members": {
        "aria-label": "Navigation de la section équipe"
      },
      "user-logged-menu": {
        "button": "Ouvrir le menu utilisateur",
        "logout": "Se déconnecter"
      }
    },
    "pages": {
      "assessment-individual-results": {
        "badges": "Résultats Thématiques",
        "progression": "Avancement",
        "result": "Résultat",
        "stages": {
          "label": "Paliers",
          "value": "{count} étoiles sur {total}"
        },
        "tab": {
          "results": "Résultats",
          "review": "Analyse"
        },
        "table": {
          "column": {
            "competences": "Compétences ({count, plural, =0 {-} other {{count}}})",
            "results": {
              "label": "Résultats",
              "tooltip": "<span class=\"screen-reader-only\">Ce participant a validé</span> {result, number, ::percent} <span class=\"screen-reader-only\">de la compétence {competence}.</span>"
            }
          },
          "empty": "En attente de résultats",
          "row-title": "Compétence",
          "title": "Résultats par compétence"
        },
        "title": "Résultats de {firstName} {lastName}"
      },
      "campaign": {
        "actions": {
          "export-results": "Exporter les résultats (.csv)",
          "unarchive": "Désarchiver la campagne"
        },
        "archived": "Campagne archivée",
        "code": "Code",
        "copy": {
          "code": {
            "default": "Copier le code",
            "success": "Copié !"
          },
          "link": {
            "default": "Copier le lien direct",
            "success": "Copié !"
          }
        },
        "created-by": "Propriétaire",
        "created-on": "Créée le",
        "empty-state": "Aucun participant pour l’instant ! Envoyez-leur le lien suivant pour rejoindre votre campagne.",
        "name": "Nom de la campagne",
        "tab": {
          "activity": "Activité",
          "results": "Résultats ({count, number})",
          "review": "Analyse",
          "settings": "Paramètres"
        }
      },
      "campaign-activity": {
        "actions": {
          "delete": "Supprimer"
        },
        "delete-participation-modal": {
          "actions": {
            "cancel": "Non",
            "confirmation": "Oui, je supprime"
          },
          "error": "Un problème est survenu lors de la suppression de la participation.",
          "success": "La participation a été supprimée avec succès.",
          "text": "Vous êtes sur le point de supprimer la participation de <strong>{firstName} {lastName}</strong>, celle-ci ne sera plus visible ni comprise dans les statistiques de la campagne.",
          "title": "Supprimer cette participation ?",
          "warning": "Le participant pourra terminer son parcours mais ne pourra plus envoyer ses résultats. Il ne pourra pas non plus participer de nouveau à cette campagne."
        },
        "table": {
          "column": {
            "delete": "Supprimer la participation",
            "first-name": "Prénom",
            "last-name": "Nom",
            "status": "Statut"
          },
          "delete-button-label": "Supprimer la participation",
          "empty": "Aucun participant",
          "row-title": "Participant",
          "see-results": "Voir les résultats de {firstName} {lastName}",
          "title": "Liste des participants"
        },
        "title": "Activité"
      },
      "campaign-creation": {
        "actions": {
          "create": "Créer la campagne",
          "delete": "Effacer"
        },
        "external-id-label": {
          "label": "Libellé de l’identifiant",
          "question-label": "Souhaitez-vous demander un identifiant externe ?",
          "suggestion": "Exemple: \"Numéro de l'étudiant\" ou \"Adresse e-mail professionnelle\" *"
        },
        "landing-page-text": {
          "label": "Texte de la page d'accueil"
        },
        "legal-warning": "* En vertu de la loi Informatique et libertés, et en tant que responsable de traitement, soyez attentifs à ne pas demander de donnée particulièrement identifiante ou signifiante si ce n’est pas absolument indispensable. Le numéro de sécurité sociale (NIR) est à proscrire ainsi que toute donnée sensible.",
        "multiple-sendings": {
          "info": "Si vous choisissez l’envoi multiple, le participant pourra envoyer plusieurs fois son profil en saisissant à nouveau le code campagne. Au sein de écri+éval, vous trouverez le dernier profil envoyé.",
          "info-title": "Envoi multiple",
          "question-label": "Souhaitez-vous permettre aux participants d'envoyer plusieurs fois leur profil ?"
        },
        "name": {
          "label": "Nom de la campagne"
        },
        "no": "Non",
        "owner": {
          "clear-label": "Supprimer la sélection",
          "collapse-label": "Réduire le menu déroulant",
          "expand-label": "Ouvrir le menu déroulant",
          "info": "Le propriétaire de la campagne ainsi que les administrateurs de cette organisation, sont les seules personnes qui peuvent modifier ou archiver cette campagne.",
          "label": "Propriétaire de la campagne",
          "placeholder": "Nom et prénom du propriétaire",
          "search-placeholder": "Rechercher un propriétaire",
          "title": "Propriétaire de la campagne"
        },
        "purpose": {
          "assessment": "Évaluer les participants",
          "assessment-info": "Une campagne d’évaluation permet de tester les participants sur des sujets précis.",
          "label": "Quel est l'objectif de votre campagne ?",
          "profiles-collection": "Collecter les profils écri+ des participants",
          "profiles-collection-info": "Une campagne de collecte de profils permet de récupérer le profil des participants : niveaux par compétence et score écri+."
        },
        "tags": {
          "COMPETENCES": "Les 16 compétences",
          "CUSTOM": "Parcours sur-mesure",
          "DISCIPLINE": "Disciplinaires",
          "OTHER": "Autres",
          "PREDEFINED": "Parcours prédéfinis",
          "SUBJECT": "Thématiques"
        },
        "tags-title": "Filtrer la recherche :",
        "target-profiles-category-label": "Filtrer les parcours par catégories",
        "target-profiles-category-placeholder": "Catégories",
        "target-profiles-label": "Sélectionner un parcours",
        "target-profiles-list-label": "Que souhaitez-vous tester ?",
        "target-profiles-search-placeholder": "Rechercher par nom",
        "test-title": {
          "label": "Titre du parcours"
        },
        "title": "Création d'une campagne",
        "yes": "Oui"
      },
      "campaign-individual-results": {
        "shared-date": "Envoyé le",
        "start-date": "Commencé le"
      },
      "campaign-individual-review": {
        "title": "Analyse pour {firstName} {lastName}"
      },
      "campaign-modification": {
        "actions": {
          "edit": "Modifier"
        },
        "campaign-modification-success-message": "Les modifications ont bien été enregistrées.",
        "campaign-name": "Nom de la campagne",
        "landing-page-text": "Texte de la page d'accueil",
        "owner": {
          "info": "Le propriétaire de la campagne ainsi que les administrateurs de cette organisation, sont les seules personnes qui peuvent modifier et archiver cette campagne.",
          "label": "Propriétaire de la campagne",
          "placeholder": "Nom et prénom du propriétaire",
          "title": "Propriétaire de la campagne"
        },
        "personalised-test-title": "Titre du parcours",
        "title": "Modification d'une campagne"
      },
      "campaign-results": {
        "average": "Moyenne des résultats",
        "filters": {
          "actions": {
            "clear": "Effacer les filtres"
          },
          "aria-label": "Filtres sur les participations",
          "participations-count": "{count, plural, =0 {0 participant} =1 {1 participant} other {{count} participants}}",
          "title": "Filtres",
          "type": {
            "badges": "Thématiques",
            "divisions": {
              "placeholder": "Classes"
            },
            "groups": {
              "empty": "Aucun groupe",
              "title": "Groupes"
            },
            "search": {
              "placeholder": "Nom, prénom",
              "title": "Recherche sur le nom et prénom"
            },
            "stages": "Paliers",
            "status": {
              "empty": "Tous les statuts",
              "title": "Statut"
            }
          }
        },
        "result": "Résultat",
        "table": {
          "column": {
            "badges": "Résultats Thématiques",
            "first-name": "Prénom",
            "last-name": "Nom",
            "results": {
              "label": "Résultats",
              "on-hold": " En attente d'envoi",
              "under-test": "En cours de test",
              "value": "{percentage, number, ::percent}"
            }
          },
          "empty": "Aucune participation",
          "row-title": "Participant",
          "title": "Liste des résultats reçus"
        },
        "tested-items": "Acquis évalués",
        "title": "Résultats",
        "validated-items": "Acquis validés"
      },
      "campaign-review": {
        "description": "En fonction du référentiel testé et des résultats de la campagne, écri+ vous recommande ces sujets à travailler, classés par degré de pertinence {bubble}.",
        "sub-table": {
          "column": {
            "source": {
              "value": "Par {source}"
            }
          },
          "row-title": "Tutoriel",
          "title": "{count, plural, =1 {1 tuto recommandé} other{{count} tutos recommandés}} par la communauté écri+"
        },
        "sub-title": "Recommandation de sujets à travailler",
        "table": {
          "analysis": {
            "caption": "Tableau des sujets à travailler, certains présentent des colonnes supplémentaires indiquant le nombre de tutoriels existant en lien avec le sujet et un accès à ces tutoriels",
            "column": {
              "relevance": {
                "ariaLabelDefaultSort": "Le tableau n'est actuellement pas trié par pertinence. Cliquez pour trier par ordre croissant.",
                "ariaLabelSortDown": "Le tableau est trié par pertinence décroissante. Cliquez pour trier en ordre croissant.",
                "ariaLabelSortUp": "Le tableau est trié par pertinence croissante. Cliquez pour trier en ordre décroissant.",
                "label": "Pertinence"
              },
              "subjects": "Sujets ({count, plural, =0 {-} other {{count}}})",
              "tutorial": {
                "aria-label": "Afficher la liste des tutos"
              },
              "tutorial-count": {
                "aria-label": "Nombre de tutoriels recommandés",
                "value": "{count, plural, =1 {1 tuto} other {{count} tutos}}"
              }
            },
            "recommendations": {
              "moderately-recommended": "Assez recommandé",
              "recommended": "Recommandé",
              "strongly-recommended": "Fortement recommandé",
              "very-strongly-recommended": "Très recommandé"
            },
            "row-title": "Sujet",
            "title": "Analyse par sujet"
          },
          "competences": {
            "column": {
              "competences": "Compétences ({count})",
              "results": {
                "label": "Résultats",
                "tooltip": "<span class=\"screen-reader-only\">Les participants ont validé</span> {result} % <span class=\"screen-reader-only\">de la compétence {competence}.</span>"
              }
            },
            "row-title": "Compétence",
            "title": "Résultats par compétence"
          },
          "empty": "En attente de résultats"
        },
        "title": "Analyse"
      },
      "campaign-settings": {
        "actions": {
          "archive": "Archiver",
          "edit": "Modifier"
        },
        "campaign-type": {
          "assessment": "Campagne d'évaluation",
          "profiles-collection": "Campagne de collecte de profils",
          "title": "Type de la campagne"
        },
        "direct-link": "Lien direct",
        "external-user-id-label": "Libellé de l'identifiant",
        "landing-page-text": "Texte de la page d'accueil",
        "multiple-sendings": {
          "status": {
            "disabled": "Non",
            "enabled": "Oui"
          },
          "title": "Envoi multiple",
          "tooltip": {
            "aria-label": "Description de la campagne d'envoi multiple",
            "text-multiple-sendings-disabled": "Si l’envoi multiple a été activé, le participant pourra envoyer plusieurs fois son profil en saisissant à nouveau le code campagne. Au sein de écri+éval, seul le dernier profil envoyé sera affiché.",
            "text-multiple-sendings-enabled": "Le participant peut envoyer plusieurs fois son profil en saisissant à nouveau le code campagne. Au sein de écri+éval, vous trouverez le dernier profil envoyé."
          }
        },
        "personalised-test-title": "Titre du parcours",
        "target-profile": {
          "title": "Parcours",
          "tooltip": "Description du parcours"
        },
        "title": "Paramètres"
      },
      "campaigns-list": {
        "action": {
          "archived": {
            "label": "Archivées"
          },
          "create": "Créer une campagne",
          "ongoing": {
            "label": "Actives"
          }
        },
        "filter": {
          "by-name": "Rechercher une campagne",
          "by-owner": "Rechercher un propriétaire",
          "clear": "Effacer les filtres",
          "legend": "Filtres pour le tableau des campagnes, des champs de saisie permettent de filtrer le tableau par le nom de la campagne et par nom du propriétaire. Des boutons permettent de filtrer les campagnes actives et archivées.",
          "results": "{total, plural, =0 {0 campagne} =1 {1 campagne} other {{total, number} campagnes}}",
          "title": "Filtres"
        },
        "navigation": "Navigation de la section Campagnes",
        "no-campaign": "Aucune campagne, pour commencer cliquez sur le bouton 'Créer une campagne'.",
        "table": {
          "column": {
            "campaign": "Nom de la campagne",
            "created-by": "Propriétaire",
            "created-on": "Créée le",
            "participants": "Participants",
            "results": "Résultats reçus"
          },
          "empty": "Aucune campagne",
          "row-title": "Campagne"
        },
        "tabs": {
          "all-campaigns": "Toutes les campagnes",
          "my-campaigns": "Mes campagnes"
        },
        "title": "Campagnes"
      },
      "certifications": {
        "description": "Sélectionnez la classe pour laquelle vous souhaitez exporter les résultats de certification (.csv) ou télécharger les attestations (.pdf).<br> Vous pouvez filtrer cette liste en renseignant le nom de la classe directement dans le champ.",
        "documentation-link": "https://ecriplus.fr",
        "documentation-link-label": "Interprétation des résultats.",
        "documentation-link-notice": "Vous trouverez en suivant ce lien quelques éléments pour interpréter les résultats : ",
        "download-attestations-button": "Télécharger les attestations",
        "download-button": "Exporter les résultats",
        "errors": {
          "invalid-division": "La classe {selectedDivision} n'existe pas.",
          "no-certificates": "Aucune attestation de certification pour la classe {selectedDivision}.",
          "no-results": "Aucun résultat de certification pour la classe {selectedDivision}."
        },
        "no-students-imported-yet": "Dans cet onglet, vous retrouverez les résultats et les attestations de certification des élèves. Vous devez, dans un premier temps, importer la base élèves de votre établissement. ",
        "select-label": "Classe",
        "title": "Résultats de certification"
      },
      "login": {
        "title": "Connectez-vous"
      },
      "login-form": {
        "active-or-retrieve": "Activez ou récupérez votre espace écri+éval",
        "email": "Adresse e-mail",
        "errors": {
          "empty-password": "Le champ mot de passe est obligatoire.",
          "invalid-email": "Votre adresse e-mail n’est pas valide.",
          "should-change-password": "Vous avez actuellement un mot de passe temporaire. Cliquez sur <a href=\"{url}\">mot de passe oublié</a> pour le réinitialiser.",
          "status": {
            "403": "L'accès à écri+éval est limité aux membres invités. Chaque espace est géré par un administrateur écri+éval propre à l'organisation qui l'utilise. Contactez-le pour qu'il vous y invite.",
            "409": "Cette invitation a déjà été acceptée ou annulée."
          }
        },
        "forgot-password": "Mot de passe oublié ?",
        "forgotten-password-url": "https://app.tests.ecriplus.fr/mot-de-passe-oublie",
        "invitation-already-accepted": "Cette invitation a déjà été acceptée. Connectez-vous ou contactez l'administrateur de votre espace écri+éval.",
        "invitation-was-cancelled": "Cette invitation n’est plus valide. Contactez l’administrateur de votre espace écri+éval.",
        "is-only-accessible": "L'accès à écri+éval est limité aux membres invités. Contactez l’administrateur écri+éval de votre organisation pour qu'il vous invite.",
        "login": "Je me connecte",
        "only-for-admin": "Réservé aux personnels de direction des établissements scolaires publics et privés sous contrat.",
        "password": "Mot de passe"
      },
      "login-or-register": {
        "login-form": {
          "button": "Se connecter",
          "title": "J'ai déjà un compte"
        },
        "register-form": {
          "button": "S'inscrire",
          "errors": {
            "default": "Le service est momentanément indisponible. Veuillez réessayer ultérieurement.",
            "email-already-exists": "Cette adresse e-mail est déjà enregistrée, connectez-vous."
          },
          "fields": {
            "button": {
              "label": "Je m'inscris"
            },
            "cgu": {
              "accept": "J'accepte les",
              "and": "et la",
              "aria-label": "Accepter les conditions d'utilisation d'écri+'",
              "data-protection-policy": "politique de confidentialité",
              "error": "Vous devez accepter les conditions d’utilisation d'écri+ et la politique de confidentialité pour créer un compte.",
              "terms-of-use": "conditions d'utilisation d'écri+"
            },
            "email": {
              "error": "Votre adresse e-mail n’est pas valide.",
              "label": "Adresse e-mail"
            },
            "first-name": {
              "error": "Votre prénom n’est pas renseigné.",
              "label": "Prénom"
            },
            "last-name": {
              "error": "Votre nom n’est pas renseigné.",
              "label": "Nom"
            },
            "password": {
              "error": "Votre mot de passe doit contenir 8 caractères au minimum et comporter au moins une majuscule, une minuscule et un chiffre.",
              "label": "Mot de passe"
            }
          },
          "title": "Je m’inscris"
        },
        "title": "Vous êtes invité(e) à rejoindre l'organisation {organizationName}"
      },
      "organization-learner": {
        "activity": {
          "assessment-summary": "Résumé des participations aux campagnes d'évaluation",
          "cards": {
            "shared": "Envoyées : {count}",
            "started": "En cours : {count}",
            "title": {
              "assessment": "Évaluations",
              "profiles-collection": "Collectes de profil"
            },
            "to-share": "En attente : {count}"
          },
          "empty-state": "Aucune activité pour l’instant ! Envoyez une campagne à {organizationLearnerFirstName} {organizationLearnerLastName} et commencez à suivre sa progression.",
          "participation-list": {
            "table": {
              "column": {
                "campaign-name": "Campagne",
                "campaign-type": "Type",
                "created-at": "Date de début",
                "shared-at": "Date d'envoi",
                "status": "Statut"
              },
              "row-title": "Participation"
            },
            "type": {
              "ASSESSMENT": "Evaluation",
              "PROFILES_COLLECTION": "Collecte de profil"
            }
          },
          "participations-title": "Participations",
          "profile-collection-summary": "Résumé des participations aux campagnes de collection de profil",
          "title": "Activité"
        },
        "header": {
          "aria-label-title": "Nom du participant"
        }
      },
      "organization-participant-activity": {
        "title": "Activité du participant"
      },
      "organization-participants": {
        "empty-state": {
          "action": "Voir mes campagnes",
          "call-to-action": "Invitez-les à rejoindre une campagne ou créez-en une nouvelle.",
          "message": "Aucun participant pour l’instant !"
        },
        "filters": {
          "actions": {
            "clear": "Effacer les filtres"
          },
          "aria-label": "Filtres sur les participants",
          "participations-count": "{count, plural, =0 {0 participant} =1 {1 participant} other {{count} participants}}",
          "title": "Filtres",
          "type": {
            "certificability": {
              "label": "Rechercher par certificabilité",
              "placeholder": "Certificabilité"
            },
            "fullName": {
              "placeholder": "Nom, prénom",
              "title": "Recherche sur le nom et prénom"
            }
          }
        },
        "page-title": "Participants",
        "table": {
          "column": {
            "first-name": "Prénom",
            "is-certifiable": {
              "label": "Certificabilité",
              "tooltip": {
                "aria-label": "Explication sur la certificabilité",
                "content": "La certificabilité signifie que le participant a atteint le niveau 1 dans au moins 5 compétences différentes. L'information provient des campagnes de collecte de profil réalisées par ce dernier. Si le participant a déjà envoyé son profil, on affiche la date ainsi que le statut du dernier envoi."
              }
            },
            "last-name": "Nom",
            "latest-participation": "Dernière participation",
            "participation-count": {
              "ariaLabelDefaultSort": "Le tableau n'est actuellement pas trié par nombre de participations. Cliquez pour trier par ordre croissant.",
              "ariaLabelSortDown": "Le tableau est trié par nombre de participation décroissant. Cliquez pour trier en ordre croissant.",
              "ariaLabelSortUp": "Le tableau est trié par nombre de participation croissant. Cliquez pour trier en ordre décroissant.",
              "label": "Nombre de participations"
            }
          },
          "empty": "Aucun participant",
          "row-title": "Participant"
        },
        "title": "{count, plural, =0 {Participants} =1 {Participant ({count})} other {Participants ({count})}}"
      },
      "participants-list": {
        "latest-participation-information-tooltip": {
          "aria-label": "Informations sur la dernière participation",
          "campaign-ASSESSMENT-type": "Évaluation",
          "campaign-PROFILES_COLLECTION-type": "Collecte de profils",
          "campaign-name": "Campagne : ",
          "campaign-status": "Statut : ",
          "campaign-type": "Type : ",
          "participation-SHARED-status": "Reçu",
          "participation-STARTED-status": "En cours",
          "participation-TO_SHARE-status": "En attente d'envoi"
        }
      },
      "preselect-target-profile": {
        "download": "Télécharger la sélection des sujets ({ numberOfTubesSelected }) (JSON, { fileSize }ko)",
        "download-filename": "selection-sujets-{ organizationName }-{ date }.json",
        "no-tube-selected": "Télécharger la sélection des sujets (JSON, { fileSize }ko)",
        "table": {
          "caption": "Sélection des sujets",
          "column": {
            "action": "Sélection",
            "mobile": "Compatible smartphone",
            "name": "Nom du sujet",
            "tablet": "Compatible tablette",
            "theme-name": "Nom de la thématique"
          },
          "is-responsive": "oui",
          "not-responsive": "non",
          "row-title": "Sujet"
        },
        "title": "Sélection des sujets"
      },
      "profiles-individual-results": {
        "certifiable": "Certifiable",
        "competences-certifiables": "COMP. CERTIFIABLES",
        "pix": "écri+",
        "pix-score": "{score, number}",
        "table": {
          "column": {
            "level": "Niveau",
            "pix-score": "Score",
            "skill": "Compétence"
          },
          "empty": "En attente de résultats",
          "row-title": "Compétence",
          "title": "Résultats par compétence"
        },
        "title": "Profil de {firstName} {lastName}"
      },
      "profiles-list": {
        "table": {
          "column": {
            "certifiable": "Certifiable",
            "competences-certifiables": "Comp. certifiables",
            "first-name": "Prénom",
            "last-name": "Nom",
            "pix-score": {
              "label": "Score",
              "value": "{score, number}"
            },
            "sending-date": {
              "label": "Date d'envoi",
              "on-hold": "En attente d'envoi"
            }
          },
          "empty": "En attente de profils",
          "row-title": "Profil",
          "title": "Liste des profils reçus"
        },
        "title": "Profils reçus"
      },
      "sco-organization-participant-activity": {
        "title": "Activité de l'élève"
      },
      "sco-organization-participants": {
        "actions": {
          "import-file": {
            "file-type-separator": " ou ",
            "label": "Importer ({types})"
          },
          "manage-account": "Gérer le compte",
          "show-actions": "Afficher les actions"
        },
        "connection-types": {
          "email": "Adresse e-mail",
          "empty": "–",
          "identifiant": "Identifiant",
          "mediacentre": "Mediacentre",
          "none": "Aucune"
        },
        "filter": {
          "actions": {
            "clear": "Effacer les filtres"
          },
          "aria-label": "Filtres sur les élèves",
          "certificability": {
            "label": "Rechercher par certificabilité",
            "placeholder": "Certificabilité"
          },
          "division": {
            "aria-label": "Entrer une classe",
            "empty": "Aucune classe",
            "label": "Rechercher par classe"
          },
          "first-name": {
            "aria-label": "Entrer un prénom",
            "label": "Rechercher par prénom"
          },
          "last-name": {
            "aria-label": "Entrer un nom",
            "label": "Rechercher par nom"
          },
          "login-method": {
            "aria-label": "Rechercher par méthode de connexion",
            "empty-option": "Méthode de connexion"
          },
          "search": {
            "aria-label": "Recherche sur le nom et prénom",
            "label": "Nom, prénom"
          },
          "students-count": "{count, plural, =0 {0 élève} =1 {1 élève} other {{count} élèves}}",
          "title": "Filtres"
        },
        "import": {
          "error-wrapper": "<div>Aucun élève n’a été importé.<br/><strong>{message}</strong><br/> Veuillez vérifier ou modifier votre base élèves et importer à nouveau.</div>",
          "global-error": "<div>Aucun élève n’a été importé.<br/>Veuillez réessayer ou nous contacter via <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"mailto:support@ecriplus.fr\">un email</a>.</div>",
          "global-success": "La liste a été importée avec succès."
        },
        "manage-authentication-method-modal": {
          "authentication-methods": "Méthodes de connexion",
          "copied": "Copié !",
          "error": {
            "default": "Une erreur interne est survenue, nos équipes sont en train de résoudre le problème. Veuillez réessayer ultérieurement.",
            "unexpected": "Quelque chose s'est mal passé. Veuillez réessayer."
          },
          "section": {
            "add-username": {
              "button": "Ajouter l’identifiant",
              "label": "Ajouter une connexion avec un identifiant"
            },
            "email": {
              "copy": "Copier l’adresse e-mail",
              "label": "Adresse e-mail"
            },
            "mediacentre": {
              "label": "Médiacentre"
            },
            "password": {
              "copy": "Copier le mot de passe unique",
              "label": "Nouveau mot de passe à usage unique",
              "warning-1": "Communiquez ce nouveau mot de passe à votre élève.",
              "warning-2": "L’élève se connecte avec ce mot de passe à usage unique.",
              "warning-3": "écri+ lui demande d'en choisir un nouveau."
            },
            "reset-password": {
              "button": "Réinitialiser le mot de passe",
              "warning": "Réinitialiser supprime le mot de passe actuel de l’élève."
            },
            "username": {
              "copy": "Copier l’identifiant",
              "label": "Identifiant"
            }
          },
          "title": "Gestion du compte écri+ de l’élève"
        },
        "no-participants": "Aucun élève pour l’instant !",
        "no-participants-action": "L’administrateur doit importer la base élèves en cliquant sur le bouton importer.",
        "page-title": "Élèves",
        "table": {
          "column": {
            "date-of-birth": "Date de naissance",
            "division": "Classe",
            "first-name": "Prénom",
            "is-certifiable": {
              "eligible": "Certifiable",
              "label": "Certificabilité",
              "non-eligible": "Non Certifiable",
              "not-available": "Non communiqué",
              "tooltip": {
                "aria-label": "Explication sur la certificabilité",
                "content": "La certificabilité signifie que l'élève a atteint le niveau 1 dans au moins 5 compétences différentes. L'information provient des campagnes de collecte de profil réalisées par ce dernier. Si l'élève a déjà envoyé son profil, on affiche la date ainsi que le statut du dernier envoi."
              }
            },
            "last-name": "Nom",
            "last-participation-date": "Dernière participation",
            "login-method": "Méthode(s) de connexion",
            "participation-count": {
              "ariaLabelDefaultSort": "Le tableau n'est actuellement pas trié par nombre de participations. Cliquez pour trier par ordre croissant.",
              "ariaLabelSortDown": "Le tableau est trié par nombre de participation décroissant. Cliquez pour trier en ordre croissant.",
              "ariaLabelSortUp": "Le tableau est trié par nombre de participation croissant. Cliquez pour trier en ordre décroissant.",
              "label": "Nombre de participations"
            }
          },
          "description": "Tableau des élèves trié par nom. Pour les élèves ayant fourni une méthode de connexion, vous pouvez, via un menu situé dans la colonne 'Actions', gérer les modes de connexion autorisés et réinitialiser le mot de passe de l'élève",
          "empty": "Aucun élève.",
          "row-title": "Élève"
        },
        "title": "{count, plural, =0 {Élèves} =1 {Élève ({count})} other {Élèves ({count})}}"
      },
      "sup-organization-participant-activity": {
        "title": "Activité de l'étudiant"
      },
      "sup-organization-participants": {
        "actions": {
          "download-template": "Télécharger le modèle",
          "edit-student-number": "Éditer le numéro étudiant",
          "import-file": "Importer",
          "show-actions": "Afficher les actions"
        },
        "edit-student-number-modal": {
          "actions": {
            "update": "Mettre à jour"
          },
          "form": {
            "error": "Le numéro étudiant ne doit pas être vide.",
            "new-student-number-label": "Nouveau numéro étudiant",
            "student-number": "Numéro étudiant actuel de {firstName} {lastName} est : ",
            "success": "La modification du numéro étudiant de {firstName} {lastName} a bien été effectué."
          },
          "title": "Édition du numéro étudiant"
        },
        "empty-state": {
          "no-participants": "Aucun étudiants pour l’instant !",
          "no-participants-action": "L’administrateur doit importer les étudiants en cliquant sur le bouton importer."
        },
        "filter": {
          "actions": {
            "clear": "Effacer les filtres"
          },
          "aria-label": "Filtrer les étudiants",
          "certificability": {
            "label": "Rechercher par certificabilité",
            "placeholder": "Certificabilité"
          },
          "group": {
            "aria-label": "Entrer un groupe",
            "empty": "Aucun groupe",
            "label": "Rechercher par groupe"
          },
          "search": {
            "aria-label": "Recherche sur le nom et prénom",
            "label": "Nom, prénom"
          },
          "student-number": {
            "aria-label": "Entrer un numéro étudiant",
            "label": "Rechercher par numéro étudiant"
          },
          "students-count": "{count, plural, =0 {0 étudiant} =1 {1 étudiant} other {{count} étudiants}}",
          "title": "Filtres"
        },
        "page-title": "Étudiants",
        "table": {
          "column": {
            "date-of-birth": "Date de naissance",
            "first-name": "Prénom",
            "group": "Groupes",
            "is-certifiable": {
              "label": "Certificabilité",
              "tooltip": {
                "aria-label": "Explication sur la certificabilité",
                "content": "La certificabilité signifie que l'étudiant a atteint le niveau 1 dans au moins 5 compétences différentes. L'information provient des campagnes de collecte de profil réalisées par ce dernier. Si l'étudiant a déjà envoyé son profil, on affiche la date ainsi que le statut du dernier envoi."
              }
            },
            "last-name": "Nom",
            "last-participation-date": "Dernière participation",
            "participation-count": {
              "ariaLabelDefaultSort": "Le tableau n'est actuellement pas trié par nombre de participations. Cliquez pour trier par ordre croissant.",
              "ariaLabelSortDown": "Le tableau est trié par nombre de participation décroissant. Cliquez pour trier en ordre croissant.",
              "ariaLabelSortUp": "Le tableau est trié par nombre de participation croissant. Cliquez pour trier en ordre décroissant.",
              "label": "Nombre de participations"
            },
            "student-number": "Numéro étudiant"
          },
          "description": "Tableau des étudiants trié par nom. Vous pouvez, via un menu situé dans une colonne supplémentaire, modifier le numéro étudiant.",
          "empty": "Aucun étudiant.",
          "row-title": "Étudiant"
        },
        "title": "{count, plural, =0 {Étudiants} =1 {Étudiant ({count})} other {Étudiants ({count})}}"
      },
      "sup-organization-participants-import": {
        "actions": {
          "add": {
            "details": "Permet d’ajouter une liste d'étudiants à celle déjà importée et/ou modifier les informations des étudiants déjà importés.",
            "label": "Ajouter des étudiants",
            "title": "Ajouter / modifier"
          },
          "replace": {
            "details": "Permet d’importer une nouvelle liste d'étudiants à la place de l’existante.",
            "label": "Importer une nouvelle liste",
            "title": "Remplacer"
          }
        },
        "description": "Quel type d'import souhaitez-vous réaliser ?",
        "error-wrapper": "<div>Aucun étudiant n’a été importé.<br/><strong>{message}</strong><br/> Veuillez modifier votre fichier et l’importer à nouveau.</div>",
        "global-error": "<div>Aucun étudiant n’a été importé.<br/>Veuillez réessayer ou nous contacter via <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"support@ecriplus.fr\">un email</a></div>",
        "global-success": "La liste a été importée avec succès.",
        "global-success-with-warnings": "<div>La liste a été importée avec succès.<br/><br/>Cependant les valeurs suivantes n’ont pas été reconnues.<br />{warnings}<br/><br/>Elles ont été remplacées par “Non reconnu”. Si vous considérez qu’il manque des valeurs dans la liste limitative, veuillez nous contacter à <a href=\"mailto:support@ecriplus.fr\">support@ecriplus.fr</a></div>",
        "supported-formats": "(Format supporté : csv)",
        "title": "Importer des étudiants",
        "warnings": {
          "diploma": "Diplômes non reconnus : {diplomas}; ",
          "study-scheme": "Régimes non reconnus : {studySchemes}; "
        }
      },
      "team-invitations": {
        "cancel-invitation": "Supprimer l’invitation",
        "invitation-cancelled-succeed-message": "L’invitation a bien été supprimée.",
        "invitation-resent-succeed-message": "L'invitation a bien été renvoyée.",
        "resend-invitation": "Renvoyer l'invitation",
        "table": {
          "column": {
            "email-address": "Adresse e-mail",
            "pending-invitation": "Date de dernier envoi"
          },
          "row": {
            "aria-label": "Invitation en attente"
          }
        },
        "title": "Invitations"
      },
      "team-list": {
        "add-member-button": "Inviter un membre",
        "tabs": {
          "invitation": "Invitations ({count, plural, =0 {-} other {{count}}})",
          "member": "Membres ({count, plural, =0 {-} other {{count}}})"
        },
        "title": "Équipe"
      },
      "team-members": {
        "actions": {
          "edit-organization-membership-role": "Modifier le rôle",
          "manage": "Gérer",
          "remove-membership": "Supprimer",
          "save": "Enregistrer",
          "select-role": {
            "label": "Sélectionner un rôle",
            "options": {
              "admin": "Administrateur",
              "member": "Membre"
            }
          }
        },
        "notifications": {
          "change-member-role": {
            "error": "Une erreur est survenue lors de la modification du rôle du membre.",
            "success": "Le rôle a bien été changé."
          },
          "remove-membership": {
            "error": "Une erreur est survenue lors de la désactivation du membre.",
            "success": "{memberFirstName} {memberLastName} a été supprimé avec succès de votre équipe écri+éval."
          }
        },
        "remove-membership-modal": {
          "actions": {
            "remove": "Oui, supprimer le membre"
          },
          "message": "{memberFirstName} {memberLastName} n'aura plus accès à cet espace écri+éval.<br>Ses campagnes restent accessibles au reste de l'équipe.",
          "title": "Confirmez-vous la suppression ?"
        },
        "table": {
          "column": {
            "first-name": "Prénom",
            "last-name": "Nom",
            "organization-membership-role": "Rôle"
          },
          "empty": "En attente de membres",
          "row-title": "Membre"
        },
        "title": "Membres"
      },
      "team-new": {
        "email-requirement": "Saisissez ici l'adresse e-mail du membre que vous souhaitez inviter.",
        "errors": {
          "default": "Le service est momentanément indisponible. Veuillez réessayer ultérieurement.",
          "status": {
            "400": "Le format de l'adresse e-mail est incorrect.",
            "404": "Cet email n'appartient à aucun utilisateur.",
            "412": "Ce membre a déjà été ajouté.",
            "500": "Quelque chose s'est mal passé. Veuillez réessayer."
          }
        },
        "invited-members": "À la réception de l'e-mail, les invités pourront choisir de se créer un compte écri+ ou de se connecter avec un compte écri+ existant.",
        "several-email-requirement": "Vous pouvez inviter plusieurs membres en séparant les adresses e-mails par des virgules.",
        "success": {
          "invitation": "Une invitation a bien été envoyée à l’adresse e-mail {email}.",
          "multiple-invitations": "Une invitation a bien été envoyée aux adresses e-mails listées."
        },
        "title": "Inviter un membre"
      },
      "team-new-item": {
        "input-label": "Adresse(s) e-mail",
        "invite-button": "Inviter"
      },
      "terms-of-service": {
        "accept": "J’accepte les conditions d’utilisation",
        "title": "Conditions d'utilisation"
      }
    }
  }]];
  _exports.default = _default;
});