define("@1024pix/pix-ui/components/pix-banner", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.displayBanner}}
    <div class="pix-banner pix-banner--{{this.type}}" role="alert" ...attributes>
      <FaIcon @icon={{this.icon}} class="pix-banner__icon" />
      <div>
        {{yield}}
        {{#if this.displayAction}}
          {{#if this.isExternalLink}}
            <a
              class="pix-banner__action"
              href={{@actionUrl}}
              target="_blank"
              rel="noopener noreferrer"
            >
              {{@actionLabel}}
              <FaIcon class="external-link" @icon="up-right-from-square" />
            </a>
          {{else}}
            <LinkTo class="pix-banner__action" @route={{@actionUrl}}>{{@actionLabel}}</LinkTo>
          {{/if}}
        {{/if}}
      </div>
      {{#if this.canCloseBanner}}
        <div class="pix-banner__close">
          <PixIconButton
            @ariaLabel="Fermer"
            @icon="xmark"
            @size="small"
            @triggerAction={{this.closeBanner}}
          />
        </div>
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "eHcjD7Kr",
    "block": "[[[41,[30,0,[\"displayBanner\"]],[[[1,\"  \"],[11,0],[16,0,[29,[\"pix-banner pix-banner--\",[30,0,[\"type\"]]]]],[24,\"role\",\"alert\"],[17,1],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"pix-banner__icon\"]],[[\"@icon\"],[[30,0,[\"icon\"]]]],null],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[18,4,null],[1,\"\\n\"],[41,[30,0,[\"displayAction\"]],[[[41,[30,0,[\"isExternalLink\"]],[[[1,\"          \"],[10,3],[14,0,\"pix-banner__action\"],[15,6,[30,2]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n            \"],[1,[30,3]],[1,\"\\n            \"],[8,[39,1],[[24,0,\"external-link\"]],[[\"@icon\"],[\"up-right-from-square\"]],null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[39,3],[[24,0,\"pix-banner__action\"]],[[\"@route\"],[[30,2]]],[[\"default\"],[[[[1,[30,3]]],[]]]]],[1,\"\\n\"]],[]]]],[]],null],[1,\"    \"],[13],[1,\"\\n\"],[41,[30,0,[\"canCloseBanner\"]],[[[1,\"      \"],[10,0],[14,0,\"pix-banner__close\"],[12],[1,\"\\n        \"],[8,[39,4],null,[[\"@ariaLabel\",\"@icon\",\"@size\",\"@triggerAction\"],[\"Fermer\",\"xmark\",\"small\",[30,0,[\"closeBanner\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"&attrs\",\"@actionUrl\",\"@actionLabel\",\"&default\"],false,[\"if\",\"fa-icon\",\"yield\",\"link-to\",\"pix-icon-button\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-banner.hbs",
    "isStrictMode": false
  });
  const TYPE_INFO = 'information';
  const TYPE_ERROR = 'error';
  const TYPE_WARNING = 'warning';
  const TYPE_COMMUNICATION = 'communication';
  const TYPE_COMMUNICATION_ORGA = 'communication-orga';
  const TYPE_COMMUNICATION_CERTIF = 'communication-certif';
  const types = [TYPE_INFO, TYPE_ERROR, TYPE_WARNING, TYPE_COMMUNICATION, TYPE_COMMUNICATION_ORGA, TYPE_COMMUNICATION_CERTIF];
  const icons = {
    [TYPE_INFO]: 'circle-info',
    [TYPE_ERROR]: 'triangle-exclamation',
    [TYPE_WARNING]: 'circle-exclamation',
    [TYPE_COMMUNICATION]: 'bullhorn',
    [TYPE_COMMUNICATION_ORGA]: 'bullhorn',
    [TYPE_COMMUNICATION_CERTIF]: 'bullhorn'
  };
  let PixBanner = (_class = class PixBanner extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "isBannerVisible", _descriptor, this);
    }
    get type() {
      return types.includes(this.args.type) ? this.args.type : TYPE_INFO;
    }
    get icon() {
      return icons[this.type];
    }
    get displayAction() {
      return this.args.actionLabel && this.args.actionUrl;
    }
    get isExternalLink() {
      return this.args.actionUrl.includes('/');
    }
    get canCloseBanner() {
      return this.args.canCloseBanner;
    }
    get displayBanner() {
      return this.isBannerVisible;
    }
    async closeBanner(params) {
      if (this.args.onCloseBannerTriggerAction) {
        await this.args.onCloseBannerTriggerAction(params);
      }
      this.isBannerVisible = false;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isBannerVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "closeBanner", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeBanner"), _class.prototype)), _class);
  _exports.default = PixBanner;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixBanner);
});