define("@1024pix/pix-ui/components/pix-indicator-card", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/object/internals", "@1024pix/pix-ui/utils/accessible-contrasted-color-generator"], function (_exports, _component, _templateFactory, _component2, _object, _internals, _accessibleContrastedColorGenerator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="indicator-card" ...attributes>
    {{#if @isLoading}}
      <p class="screen-reader-only">{{@loadingMessage}}</p>
      <div class="indicator-card__icon" aria-hidden="true"></div>
      <div class="indicator-card__content" aria-hidden="true"></div>
    {{else}}
      <div
        id={{this.iconId}}
        class="indicator-card__icon"
        aria-hidden="true"
        {{did-insert this.setIconColor}}
      >
        <FaIcon @icon={{@icon}} @prefix={{@iconPrefix}} />
      </div>
      <dl class="indicator-card__content">
        <div class="indicator-card__title">
          <dt>{{@title}}</dt>
          {{#if @info}}
            <PixTooltip
              @id={{this.tooltipId}}
              @position="top"
              @isWide={{true}}
              class="indicator-card__tooltip hide-on-mobile"
            >
              <:triggerElement>
                <FaIcon
                  @icon="circle-question"
                  class="indicator-card__tooltip-icon"
                  tabindex="0"
                  aria-describedby={{this.tooltipId}}
                />
              </:triggerElement>
              <:tooltip>
                {{@info}}
              </:tooltip>
            </PixTooltip>
          {{/if}}
        </div>
        <dd class="indicator-card__value">{{yield}}</dd>
        <p class="indicator-card__sub">
          {{yield to="sub"}}
        </p>
      </dl>
    {{/if}}
  </section>
  */
  {
    "id": "K90dZlxE",
    "block": "[[[11,\"section\"],[24,0,\"indicator-card\"],[17,1],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,2],[14,0,\"screen-reader-only\"],[12],[1,[30,3]],[13],[1,\"\\n    \"],[10,0],[14,0,\"indicator-card__icon\"],[14,\"aria-hidden\",\"true\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"indicator-card__content\"],[14,\"aria-hidden\",\"true\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[11,0],[16,1,[30,0,[\"iconId\"]]],[24,0,\"indicator-card__icon\"],[24,\"aria-hidden\",\"true\"],[4,[38,1],[[30,0,[\"setIconColor\"]]],null],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@icon\",\"@prefix\"],[[30,4],[30,5]]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"dl\"],[14,0,\"indicator-card__content\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"indicator-card__title\"],[12],[1,\"\\n        \"],[10,\"dt\"],[12],[1,[30,6]],[13],[1,\"\\n\"],[41,[30,7],[[[1,\"          \"],[8,[39,3],[[24,0,\"indicator-card__tooltip hide-on-mobile\"]],[[\"@id\",\"@position\",\"@isWide\"],[[30,0,[\"tooltipId\"]],\"top\",true]],[[\"triggerElement\",\"tooltip\"],[[[[1,\"\\n              \"],[8,[39,2],[[24,0,\"indicator-card__tooltip-icon\"],[24,\"tabindex\",\"0\"],[16,\"aria-describedby\",[30,0,[\"tooltipId\"]]]],[[\"@icon\"],[\"circle-question\"]],null],[1,\"\\n            \"]],[]],[[[1,\"\\n              \"],[1,[30,7]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n      \"],[10,\"dd\"],[14,0,\"indicator-card__value\"],[12],[18,8,null],[13],[1,\"\\n      \"],[10,2],[14,0,\"indicator-card__sub\"],[12],[1,\"\\n        \"],[18,9,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"@isLoading\",\"@loadingMessage\",\"@icon\",\"@iconPrefix\",\"@title\",\"@info\",\"&default\",\"&sub\"],false,[\"if\",\"did-insert\",\"fa-icon\",\"pix-tooltip\",\"yield\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-indicator-card.hbs",
    "isStrictMode": false
  });
  let PixIndicatorCard = (_class = class PixIndicatorCard extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "id", (0, _internals.guidFor)(this));
      _defineProperty(this, "iconId", 'icon-' + this.id);
      _defineProperty(this, "tooltipId", 'tooltip-' + this.id);
    }
    setIconColor() {
      const element = document.getElementById(this.iconId);
      try {
        element.style.color = this.args.color;
        element.style.backgroundColor = (0, _accessibleContrastedColorGenerator.default)(this.args.color);
      } catch (error) {
        console.error(error.message);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "setIconColor", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setIconColor"), _class.prototype)), _class);
  _exports.default = PixIndicatorCard;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixIndicatorCard);
});