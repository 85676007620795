define("@1024pix/pix-ui/components/pix-search-input", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/object/internals", "lodash.debounce"], function (_exports, _component, _templateFactory, _component2, _object, _internals, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="pix-search-input">
    {{#if this.label}}
      <label for={{this.searchInputId}} class="pix-search-input__label">{{this.label}}</label>
    {{/if}}
  
    <div class="pix-search-input__input-container">
      <FaIcon @icon="magnifying-glass" />
      {{! template-lint-disable require-input-label }}
      <input
        id={{this.searchInputId}}
        class="pix-search-input__input"
        role="searchbox"
        name={{@inputName}}
        placeholder={{@placeholder}}
        aria-label={{this.ariaLabel}}
        value={{@value}}
        oninput={{this.onSearch}}
        ...attributes
      />
    </div>
  </div>
  */
  {
    "id": "MClE9VRF",
    "block": "[[[10,0],[14,0,\"pix-search-input\"],[12],[1,\"\\n\"],[41,[30,0,[\"label\"]],[[[1,\"    \"],[10,\"label\"],[15,\"for\",[30,0,[\"searchInputId\"]]],[14,0,\"pix-search-input__label\"],[12],[1,[30,0,[\"label\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,0,\"pix-search-input__input-container\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@icon\"],[\"magnifying-glass\"]],null],[1,\"\\n\"],[1,\"    \"],[11,\"input\"],[16,1,[30,0,[\"searchInputId\"]]],[24,0,\"pix-search-input__input\"],[24,\"role\",\"searchbox\"],[16,3,[30,1]],[16,\"placeholder\",[30,2]],[16,\"aria-label\",[30,0,[\"ariaLabel\"]]],[16,2,[30,3]],[16,\"oninput\",[30,0,[\"onSearch\"]]],[17,4],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@inputName\",\"@placeholder\",\"@value\",\"&attrs\"],false,[\"if\",\"fa-icon\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-search-input.hbs",
    "isStrictMode": false
  });
  let PixSearchInput = (_class = class PixSearchInput extends _component2.default {
    constructor() {
      super(...arguments);
      this.debounceTimeBeforeSearch = parseInt(this.args.debounceTimeInMs);
      if (isNaN(this.debounceTimeBeforeSearch)) {
        throw new Error('ERROR in PixSearchInput component, @debounceTimeInMs param is not provided');
      }
      if (!this.args.triggerFiltering) {
        throw new Error('ERROR in PixSearchInput component, @triggerFiltering param is not provided');
      }
      if (!this.args.label && !this.args.ariaLabel) {
        throw new Error('ERROR in PixSearchInput component, you must provide @label or @ariaLabel params');
      }
      this.searchInputId = this.args.id || (0, _internals.guidFor)(this);
      this.debouncedTriggerFiltering = (0, _lodash.default)(this.args.triggerFiltering, this.debounceTimeBeforeSearch);
    }
    get label() {
      return this.args.label;
    }
    get ariaLabel() {
      return this.args.label ? null : this.args.ariaLabel;
    }
    async onSearch(event) {
      await this.debouncedTriggerFiltering(this.searchInputId, event.target.value);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onSearch", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSearch"), _class.prototype)), _class);
  _exports.default = PixSearchInput;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixSearchInput);
});