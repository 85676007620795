define("ember-dayjs/dist/_app_/helpers/local", ["exports", "ember-dayjs/helpers/local.js"], function (_exports, _local) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _local.default;
    }
  });
});