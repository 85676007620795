define("ember-dayjs/dist/_app_/helpers/dayjs-days-in-month", ["exports", "ember-dayjs/helpers/dayjs-days-in-month.js"], function (_exports, _dayjsDaysInMonth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dayjsDaysInMonth.default;
    }
  });
});