define("ember-dayjs/dist/helpers/dayjs-format", ["exports", "ember-dayjs/dist/helpers/base-helper.js", "ember-dayjs/dist/_rollupPluginBabelHelpers-7db585ca.js", "@ember/component/helper", "@ember/runloop", "@ember/service"], function (_exports, _baseHelper, _rollupPluginBabelHelpers7db585ca, _helper, _runloop, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DayjsFormat extends _baseHelper.default {
    async compute(params, hash) {
      var _hash$locale, _hash$locale2;
      super.compute(params, hash);
      if (hash.inputFormat) {
        await this.dayjs.extend('customParseFormat');
      }
      await this.dayjs.useLocale((_hash$locale = hash.locale) !== null && _hash$locale !== void 0 ? _hash$locale : this.dayjs.locale);
      return this.dayjs.self(params[0], hash.inputFormat).locale((_hash$locale2 = hash.locale) !== null && _hash$locale2 !== void 0 ? _hash$locale2 : this.dayjs.locale).format(params[1]);
    }
  }
  _exports.default = DayjsFormat;
});