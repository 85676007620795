define("@1024pix/pix-ui/components/pix-button", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/tracking", "@1024pix/pix-ui/components/pix-button-base"], function (_exports, _component, _templateFactory, _object, _tracking, _pixButtonBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @route}}
    <LinkTo
      @route={{@route}}
      @models={{if @model (array @model) this.defaultModel}}
      class={{this.className}}
      ...attributes
    >
      {{yield}}
    </LinkTo>
  {{else}}
    <button
      type={{this.type}}
      class={{this.className}}
      {{on "click" this.triggerAction}}
      ...attributes
      disabled={{this.isButtonLoadingOrDisabled}}
      aria-disabled="{{this.ariaDisabled}}"
    >
      {{#if this.isLoading}}
        <div class="loader loader--{{this.loadingColor}}">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
        <span class="loader__not-visible-text">{{yield}}</span>
      {{else}}
        {{yield}}
      {{/if}}
    </button>
  {{/if}}
  */
  {
    "id": "hoBAaTM4",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],[[16,0,[30,0,[\"className\"]]],[17,2]],[[\"@route\",\"@models\"],[[30,1],[52,[30,3],[28,[37,2],[[30,3]],null],[30,0,[\"defaultModel\"]]]]],[[\"default\"],[[[[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,\"button\"],[16,4,[30,0,[\"type\"]]],[16,0,[30,0,[\"className\"]]],[17,2],[16,\"disabled\",[30,0,[\"isButtonLoadingOrDisabled\"]]],[16,\"aria-disabled\",[29,[[30,0,[\"ariaDisabled\"]]]]],[4,[38,4],[\"click\",[30,0,[\"triggerAction\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"isLoading\"]],[[[1,\"      \"],[10,0],[15,0,[29,[\"loader loader--\",[30,0,[\"loadingColor\"]]]]],[12],[1,\"\\n        \"],[10,0],[14,0,\"bounce1\"],[12],[13],[1,\"\\n        \"],[10,0],[14,0,\"bounce2\"],[12],[13],[1,\"\\n        \"],[10,0],[14,0,\"bounce3\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,0,\"loader__not-visible-text\"],[12],[18,4,null],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[18,4,null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]]]],[\"@route\",\"&attrs\",\"@model\",\"&default\"],false,[\"if\",\"link-to\",\"array\",\"yield\",\"on\"]]",
    "moduleName": "@1024pix/pix-ui/components/pix-button.hbs",
    "isStrictMode": false
  });
  let PixButton = (_class = class PixButton extends _pixButtonBase.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "text", 'pix-button');
      _defineProperty(this, "defaultModel", []);
      _initializerDefineProperty(this, "isTriggering", _descriptor, this);
    }
    get isLoading() {
      return this.args.isLoading || this.isTriggering;
    }
    get type() {
      return this.args.type || 'button';
    }
    get loadingColor() {
      return this.args.loadingColor || this.args['loading-color'] || 'white';
    }
    get isButtonLoadingOrDisabled() {
      return this.isLoading || this.args.isDisabled;
    }
    get ariaDisabled() {
      return this.isButtonLoadingOrDisabled;
    }
    get className() {
      return super.baseClassNames.join(' ');
    }
    async triggerAction(params) {
      if (this.type === 'submit' && !this.args.triggerAction) return;
      if (!this.args.triggerAction) {
        throw new Error('@triggerAction params is required for PixButton !');
      }
      try {
        this.isTriggering = true;
        await this.args.triggerAction(params);
      } finally {
        this.isTriggering = false;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isTriggering", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "triggerAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "triggerAction"), _class.prototype)), _class);
  _exports.default = PixButton;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PixButton);
});