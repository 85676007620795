define("ember-dayjs/dist/_app_/helpers/dayjs-is-before", ["exports", "ember-dayjs/helpers/dayjs-is-before.js"], function (_exports, _dayjsIsBefore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dayjsIsBefore.default;
    }
  });
});