define("ember-dayjs/dist/helpers/dayjs-add", ["exports", "ember-dayjs/dist/helpers/base-helper.js", "ember-dayjs/dist/_rollupPluginBabelHelpers-7db585ca.js", "@ember/component/helper", "@ember/runloop", "@ember/service"], function (_exports, _baseHelper, _rollupPluginBabelHelpers7db585ca, _helper, _runloop, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DayjsAdd extends _baseHelper.default {
    compute(params, hash) {
      super.compute(params, hash);
      return this.dayjs.self(params[0]).add(params[1], hash.precision);
    }
  }
  _exports.default = DayjsAdd;
});